.editor-container-outer:not(.display) {
  background-color: var(--color-surface);
}

.editor-container-inner:not(.display) {
  background-color: var(--color-s01dp);
}

.editor-root:not(.display) {
  border: 1px solid var(--color-s06dp);
  outline: none !important;
}

.editor-root:focus-visible:not(.display) {
  border: 1px solid var(--color-primary);
}

.editor-root {
  overflow: auto;  overflow: auto;
  padding: 1px 2px;
  border-radius: 0.25rem;
  -webkit-transition: border-color .25 ease-out;
  -moz-transition: border-color .25s ease-out;
    -o-transition: border-color .25s ease-out;
       transition: border-color .25s ease-out;
}

.editor-root *::selection {
  background-color: #c78de366;
}

.ltr {
  text-align: left;
}

.editor-root h1 {
  font-size: 24px;
}

.editor-root h2 {
  font-size: 20px;
}

.editor-root h3 {
  font-size: 18px;
}

.editor-root blockquote {
  border-left: 4px solid var(--color-primary-light);
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  font-style: italic;
}

.editor-paragraph {
  position: relative;
  font-family: 'Roboto', sans-serif;
}

.editor-hashtag {
  color: var(--color-primary-light);
  font-weight: bold;
  margin: 0 0 0 0;
  padding: 1px 2px;
  /* background-color: var(--color-primary); */
  border-radius: .25rem;
  letter-spacing: .03ch;
}

.editor-mention {
  font-weight: bold;
  background-color: var(--color-mention-background) !important;
  color: white;
  border-radius: .25rem;
  letter-spacing: .03ch;
}

.editor-root a {
  font-weight: 500;
  color: var(--color-text-primary-brand-small);
}

.editor-root a:not(.no-underline) {
  text-decoration: underline;
}

.editor-embed, .editor-tku-quote, .editor-post-quote {
  font-weight: bold;
  color: white;
  background-color: var(--color-secondary) !important;
  border-radius: .25rem;
  padding: 1px 2px;
  cursor: pointer;
}

.editor-custom-emoji {
  color: transparent;
  caret-color: var(--color-text-primary);
  background-size: 18px 18px;
  background-position: center;
  background-repeat: no-repeat;
}

.editor-custom-emoji-inner {
  color: transparent;
}

.editor-custom-emoji-inner::selection, .editor-mention-inner::selection {
  color: transparent;
}
