/* TODO: update this file when all post content is complete */

.tku-post-content .twitter-tweet.twitter-tweet-rendered {
  max-width: 450px !important;
}

.tku-post-image {
  max-height: 300px;
}

/* Update for Mobile break */
@media screen and (max-width: 400px) {

  /* .tku-post-content .twitter-tweet.twitter-tweet-rendered {
    max-width: 250px !important;
  } */

  .tku-post-image {
    max-height: 200px;
  }
}

.tku-post-preview {
  max-height: 90px;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(180deg,#000 30%,transparent);
  mask-image: linear-gradient(180deg,#000 30%,transparent);
}