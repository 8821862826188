.editor-display-topic-root {
  padding: 5px 0px 5px 4px;
  background-color: transparent;
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
}

.editor-display-topic-root .editor-paragraph {
  line-height: 22px;
}

.editor-mobile .editor-display-topic-root {
  font-size: 18px;
}

.editor-mobile .editor-display-topic-root .editor-paragraph {
  line-height: 18px;
}

/* .editor-mobile .editor-display-topic-root {
  font-size: 18px;
} */

.editor-display-topic-root br {
  display: none;
}

/* .editor-display-topic-root .editor-paragraph {
  padding: 0px;
  line-height: 16px;
} */

/* .editor-display-topic-root .editor-emoji {
  display: inline-block;
  margin: 3px 0px;
  font-size: 18px;
} */