.editor-display-post-quote {
  overflow: hidden;
  font-size: 14px !important;
}




/* .editor-display-post-quote p {
  margin: 3px 0;
}

.editor-display-post-root.editor-display-post-quote {
  padding: 0px 6px !important; 
}

.editor-display-post-quote .display-post-quote {
  padding: 0 !important;
} */
