/* Ripple effect */
.tku-btn.ripple {
  background-position: center;
  transition: background 0.7s, color 0.7s;
}

.tku-btn.tku-btn-primary.ripple:hover {
  background: var(--color-primary-light) radial-gradient(circle, transparent 1%, var(--color-primary-light) 1%) center/15000%;
}

.tku-btn.tku-btn-secondary.ripple:hover {
  background: var(--color-secondary-light) radial-gradient(circle, transparent 1%, var(--color-secondary-light) 1%) center/15000%;
}

.tku-btn.tku-btn-outline-primary.ripple:hover {
  background: var(--color-primary-light) radial-gradient(circle, transparent 1%, var(--color-primary-light) 1%) center/15000%;
  color: white;
}

.tku-btn.tku-btn-outline-secondary.ripple:hover {
  background: var(--color-secondary-light) radial-gradient(circle, transparent 1%, var(--color-secondary-light) 1%) center/15000%;
  color: white;
}

.tku-btn.tku-btn-primary.ripple:active {
  background-color: var(--color-primary);
}

.tku-btn.tku-btn-secondary.ripple:active {
  background-color: var(--color-secondary);
}

.tku-btn.tku-btn-outline-primary.ripple:active {
  background-color: var(--color-primary);
}

.tku-btn.tku-btn-outline-secondary.ripple:active {
  background-color: var(--color-secondary);
}

.tku-btn.tku-btn-primary.ripple:active, 
.tku-btn.tku-btn-secondary.ripple:active,
.tku-btn.tku-btn-outline-primary.ripple:active,
.tku-btn.tku-btn-outline-secondary.ripple:active {
  background-size: 100%;
  transition: background 0s;
}

