.editor-display-post-root {
  background-color: transparent;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
}

.editor-paragraph.ltr,.editor-paragraph:first-of-type {
  margin: .3rem 0;
}

.editor-display-post-root .editor-paragraph.ltr span {
  line-height: 18px;
}

/* .editor-display-post-root br {
  display: none;
} */

@media screen and (min-width: 768px) {
  .editor-display-post-root .post-embed { 
    max-width: 500px;
  }
}


