/* Default */
.virtuoso-grid-item-container {
  width: 33%;
  height: 100px;
  display: flex;
  flex: none;
  align-content: stretch;
  box-sizing: border-box;
}

@media (min-width: 792px) {
  .virtuoso-grid-item-container {
    height: 200px;
  }
}

@media (min-width: 992px) {
  .virtuoso-grid-item-container {
    height: 235px;
  }
}

.virtuoso-grid-list-container {
  display: flex;
  flex-wrap: wrap;
  height: 300px;
  width: 100%;
  padding-bottom: 40px;
  border-radius: .25rem;
}

/* Store - Badges */

@media (max-width: 991px) {
  .virtuoso-grid-item-container-store-badges {
    width: 50%;
    height: 215px;
  }
}

@media (max-width: 390px) {
  .virtuoso-grid-item-container-store-badges {
    height: 250px;
  }
}

@media (min-width: 992px) {
  .virtuoso-grid-item-container-store-badges {
    width: 33%;
    height: 215px;
  }
}

.virtuoso-grid-item-container-store-badges {
  display: flex;
  flex: none;
  align-content: stretch;
  box-sizing: border-box;
}

.virtuoso-grid-list-container-store-badges {
  display: flex;
  flex-wrap: wrap;
  height: 215px;
  width: 100%;
  padding-bottom: 20px;
  border-radius: .25rem;
}

@media (max-width: 390px) {
  .virtuoso-grid-list-container-store-badges {
    height: 250px;
  }
}