@media screen and (min-width: 768px) {
    
  *::-webkit-scrollbar, [data-test-id="virtuoso-scroller"]::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb, [data-test-id="virtuoso-scroller"]::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--color-s03dp);;
  }

  *::-webkit-scrollbar-track, [data-test-id="virtuoso-scroller"]::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    border-radius: 10px;
    background-color: transparent;
  }
}

/* https://codepen.io/devstreak/pen/dMYgeO */
