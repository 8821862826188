.checkmark {
  display: inline-block;
  width: 8px;
  height: 30px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  position: relative;
}

.checkmark_stem {
  position: absolute;
  width: 5px;
  height: 25px;
  left: 11px;
  top: 6px;
}

.checkmark_kick {
  position: absolute;
  width: 11px;
  height: 5px;
  left: 3px;
  top: 26px;
}