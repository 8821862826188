@charset "UTF-8";
/*
Generated by twemoji-amazing, available at https://github.com/SebastianAigner/twemoji-amazing
The graphics are licensed under the CC-BY 4.0: https://github.com/twitter/twemoji
*/


/* Generated a total of 3577 emoji CSS classes. */


/*
Preamble from https://ellekasai.github.io/twemoji-awesome/,
licensed under MIT: https://ellekasai.mit-license.org/
*/

.twa {
    display: inline-block;
    height: 1em;
    width: 1em;
    margin: 0 .05em 0 .1em;
    vertical-align: -0.1em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1em 1em;
}

.twa-lg {
    height: 1.33em;
    width: 1.33em;
    margin: 0 0.0665em 0 0.133em;
    vertical-align: -0.133em;
    background-size: 1.33em 1.33em;
}

.twa-2x {
    height: 2em;
    width: 2em;
    margin: 0 0.1em 0 0.2em;
    vertical-align: -0.2em;
    background-size: 2em 2em;
}

.twa-3x {
    height: 3em;
    width: 3em;
    margin: 0 0.15em 0 0.3em;
    vertical-align: -0.3em;
    background-size: 3em 3em;
}

.twa-4x {
    height: 4em;
    width: 4em;
    margin: 0 0.2em 0 0.4em;
    vertical-align: -0.4em;
    background-size: 4em 4em;
}

.twa-5x {
    height: 5em;
    width: 5em;
    margin: 0 0.25em 0 0.5em;
    vertical-align: -0.5em;
    background-size: 5em 5em;
}

/* End preamble; begin emoji classes  const skinTones = ['medium-light-skin-tone', 'medium-skin-tone', 'medium-dark-skin-tone', 'light-skin-tone', 'dark-skin-tone', 'default-skin-tone'];
*/


.twa-1st-place-medal {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f947.svg")
}
.twa-2nd-place-medal {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f948.svg")
}
.twa-3rd-place-medal {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f949.svg")
}
.twa-a-button-blood-type {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f170.svg")
}
.twa-ab-button-blood-type {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f18e.svg")
}
.twa-abacus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ee.svg")
}
.twa-accordion {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa97.svg")
}
.twa-adhesive-bandage {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa79.svg")
}
.twa-admission-tickets {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f39f.svg")
}
.twa-aerial-tramway {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a1.svg")
}
.twa-airplane {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2708.svg")
}
.twa-airplane-arrival {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6ec.svg")
}
.twa-airplane-departure {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6eb.svg")
}
.twa-alarm-clock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/23f0.svg")
}
.twa-alembic {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2697.svg")
}
.twa-alien {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f47d.svg")
}
.twa-alien-monster {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f47e.svg")
}
.twa-ambulance {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f691.svg")
}
.twa-american-football {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c8.svg")
}
.twa-amphora {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3fa.svg")
}
.twa-anatomical-heart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fac0.svg")
}
.twa-anchor {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2693.svg")
}
.twa-anger-symbol {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4a2.svg")
}
.twa-angry-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f620.svg")
}
.twa-angry-face-with-horns {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f47f.svg")
}
.twa-anguished-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f627.svg")
}
.twa-ant {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f41c.svg")
}
.twa-antenna-bars {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4f6.svg")
}
.twa-anxious-face-with-sweat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f630.svg")
}
.twa-aquarius {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2652.svg")
}
.twa-aries {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2648.svg")
}
.twa-articulated-lorry {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f69b.svg")
}
.twa-artist {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f3a8.svg")
}
.twa-artist-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f3a8.svg")
}
.twa-artist-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f3a8.svg")
}
.twa-artist-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f3a8.svg")
}
.twa-artist-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f3a8.svg")
}
.twa-artist-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f3a8.svg")
}
.twa-artist-palette {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3a8.svg")
}
.twa-astonished-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f632.svg")
}
.twa-astronaut {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f680.svg")
}
.twa-astronaut-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f680.svg")
}
.twa-astronaut-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f680.svg")
}
.twa-astronaut-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f680.svg")
}
.twa-astronaut-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f680.svg")
}
.twa-astronaut-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f680.svg")
}
.twa-atm-sign {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3e7.svg")
}
.twa-atom-symbol {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/269b.svg")
}
.twa-auto-rickshaw {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6fa.svg")
}
.twa-automobile {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f697.svg")
}
.twa-avocado {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f951.svg")
}
.twa-axe {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa93.svg")
}
.twa-b-button-blood-type {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f171.svg")
}
.twa-baby {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f476.svg")
}
.twa-baby-angel {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f47c.svg")
}
.twa-baby-angel-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f47c-1f3ff.svg")
}
.twa-baby-angel-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f47c-1f3fb.svg")
}
.twa-baby-angel-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f47c-1f3fe.svg")
}
.twa-baby-angel-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f47c-1f3fc.svg")
}
.twa-baby-angel-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f47c-1f3fd.svg")
}
.twa-baby-bottle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f37c.svg")
}
.twa-baby-chick {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f424.svg")
}
.twa-baby-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f476-1f3ff.svg")
}
.twa-baby-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f476-1f3fb.svg")
}
.twa-baby-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f476-1f3fe.svg")
}
.twa-baby-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f476-1f3fc.svg")
}
.twa-baby-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f476-1f3fd.svg")
}
.twa-baby-symbol {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6bc.svg")
}
.twa-back-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f519.svg")
}
.twa-backhand-index-pointing-down {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f447.svg")
}
.twa-backhand-index-pointing-down-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f447-1f3ff.svg")
}
.twa-backhand-index-pointing-down-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f447-1f3fb.svg")
}
.twa-backhand-index-pointing-down-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f447-1f3fe.svg")
}
.twa-backhand-index-pointing-down-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f447-1f3fc.svg")
}
.twa-backhand-index-pointing-down-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f447-1f3fd.svg")
}
.twa-backhand-index-pointing-left {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f448.svg")
}
.twa-backhand-index-pointing-left-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f448-1f3ff.svg")
}
.twa-backhand-index-pointing-left-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f448-1f3fb.svg")
}
.twa-backhand-index-pointing-left-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f448-1f3fe.svg")
}
.twa-backhand-index-pointing-left-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f448-1f3fc.svg")
}
.twa-backhand-index-pointing-left-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f448-1f3fd.svg")
}
.twa-backhand-index-pointing-right {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f449.svg")
}
.twa-backhand-index-pointing-right-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f449-1f3ff.svg")
}
.twa-backhand-index-pointing-right-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f449-1f3fb.svg")
}
.twa-backhand-index-pointing-right-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f449-1f3fe.svg")
}
.twa-backhand-index-pointing-right-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f449-1f3fc.svg")
}
.twa-backhand-index-pointing-right-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f449-1f3fd.svg")
}
.twa-backhand-index-pointing-up {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f446.svg")
}
.twa-backhand-index-pointing-up-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f446-1f3ff.svg")
}
.twa-backhand-index-pointing-up-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f446-1f3fb.svg")
}
.twa-backhand-index-pointing-up-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f446-1f3fe.svg")
}
.twa-backhand-index-pointing-up-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f446-1f3fc.svg")
}
.twa-backhand-index-pointing-up-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f446-1f3fd.svg")
}
.twa-backpack {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f392.svg")
}
.twa-bacon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f953.svg")
}
.twa-badger {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9a1.svg")
}
.twa-badminton {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3f8.svg")
}
.twa-bagel {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f96f.svg")
}
.twa-baggage-claim {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6c4.svg")
}
.twa-baguette-bread {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f956.svg")
}
.twa-balance-scale {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2696.svg")
}
.twa-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b2.svg")
}
.twa-ballet-shoes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa70.svg")
}
.twa-balloon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f388.svg")
}
.twa-ballot-box-with-ballot {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5f3.svg")
}
.twa-banana {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f34c.svg")
}
.twa-banjo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa95.svg")
}
.twa-bank {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3e6.svg")
}
.twa-bar-chart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4ca.svg")
}
.twa-barber-pole {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f488.svg")
}
.twa-baseball {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26be.svg")
}
.twa-basket {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9fa.svg")
}
.twa-basketball {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c0.svg")
}
.twa-bat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f987.svg")
}
.twa-bathtub {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6c1.svg")
}
.twa-battery {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f50b.svg")
}
.twa-beach-with-umbrella {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3d6.svg")
}
.twa-beaming-face-with-smiling-eyes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f601.svg")
}
.twa-bear {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f43b.svg")
}
.twa-beating-heart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f493.svg")
}
.twa-beaver {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ab.svg")
}
.twa-bed {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6cf.svg")
}
.twa-beer-mug {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f37a.svg")
}
.twa-beetle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fab2.svg")
}
.twa-bell {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f514.svg")
}
.twa-bell-pepper {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fad1.svg")
}
.twa-bell-with-slash {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f515.svg")
}
.twa-bellhop-bell {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6ce.svg")
}
.twa-bento-box {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f371.svg")
}
.twa-beverage-box {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9c3.svg")
}
.twa-bicycle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b2.svg")
}
.twa-bikini {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f459.svg")
}
.twa-billed-cap {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9e2.svg")
}
.twa-biohazard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2623.svg")
}
.twa-bird {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f426.svg")
}
.twa-birthday-cake {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f382.svg")
}
.twa-bison {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ac.svg")
}
.twa-black-cat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f408-200d-2b1b.svg")
}
.twa-black-circle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26ab.svg")
}
.twa-black-flag {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3f4.svg")
}
.twa-black-heart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5a4.svg")
}
.twa-black-large-square {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2b1b.svg")
}
.twa-black-medium-small-square {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/25fe.svg")
}
.twa-black-medium-square {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/25fc.svg")
}
.twa-black-nib {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2712.svg")
}
.twa-black-small-square {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/25aa.svg")
}
.twa-black-square-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f532.svg")
}
.twa-blossom {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f33c.svg")
}
.twa-blowfish {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f421.svg")
}
.twa-blue-book {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4d8.svg")
}
.twa-blue-circle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f535.svg")
}
.twa-blue-heart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f499.svg")
}
.twa-blue-square {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f7e6.svg")
}
.twa-blueberries {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fad0.svg")
}
.twa-boar {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f417.svg")
}
.twa-bomb {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4a3.svg")
}
.twa-bone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b4.svg")
}
.twa-bookmark {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f516.svg")
}
.twa-bookmark-tabs {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4d1.svg")
}
.twa-books {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4da.svg")
}
.twa-boomerang {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa83.svg")
}
.twa-bottle-with-popping-cork {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f37e.svg")
}
.twa-bouquet {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f490.svg")
}
.twa-bow-and-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3f9.svg")
}
.twa-bowl-with-spoon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f963.svg")
}
.twa-bowling {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3b3.svg")
}
.twa-boxing-glove {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f94a.svg")
}
.twa-boy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f466.svg")
}
.twa-boy-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f466-1f3ff.svg")
}
.twa-boy-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f466-1f3fb.svg")
}
.twa-boy-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f466-1f3fe.svg")
}
.twa-boy-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f466-1f3fc.svg")
}
.twa-boy-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f466-1f3fd.svg")
}
.twa-brain {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9e0.svg")
}
.twa-bread {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f35e.svg")
}
.twa-breast-feeding {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f931.svg")
}
.twa-breast-feeding-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f931-1f3ff.svg")
}
.twa-breast-feeding-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f931-1f3fb.svg")
}
.twa-breast-feeding-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f931-1f3fe.svg")
}
.twa-breast-feeding-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f931-1f3fc.svg")
}
.twa-breast-feeding-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f931-1f3fd.svg")
}
.twa-brick {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9f1.svg")
}
.twa-bridge-at-night {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f309.svg")
}
.twa-briefcase {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4bc.svg")
}
.twa-briefs {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa72.svg")
}
.twa-bright-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f506.svg")
}
.twa-broccoli {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f966.svg")
}
.twa-broken-heart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f494.svg")
}
.twa-broom {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9f9.svg")
}
.twa-brown-circle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f7e4.svg")
}
.twa-brown-heart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f90e.svg")
}
.twa-brown-square {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f7eb.svg")
}
.twa-bubble-tea {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cb.svg")
}
.twa-bucket {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1faa3.svg")
}
.twa-bug {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f41b.svg")
}
.twa-building-construction {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3d7.svg")
}
.twa-bullet-train {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f685.svg")
}
.twa-bullseye {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3af.svg")
}
.twa-burrito {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f32f.svg")
}
.twa-bus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f68c.svg")
}
.twa-bus-stop {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f68f.svg")
}
.twa-bust-in-silhouette {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f464.svg")
}
.twa-busts-in-silhouette {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f465.svg")
}
.twa-butter {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9c8.svg")
}
.twa-butterfly {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f98b.svg")
}
.twa-cactus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f335.svg")
}
.twa-calendar {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4c5.svg")
}
.twa-call-me-hand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f919.svg")
}
.twa-call-me-hand-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f919-1f3ff.svg")
}
.twa-call-me-hand-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f919-1f3fb.svg")
}
.twa-call-me-hand-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f919-1f3fe.svg")
}
.twa-call-me-hand-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f919-1f3fc.svg")
}
.twa-call-me-hand-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f919-1f3fd.svg")
}
.twa-camel {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f42a.svg")
}
.twa-camera {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4f7.svg")
}
.twa-camera-with-flash {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4f8.svg")
}
.twa-camping {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3d5.svg")
}
.twa-cancer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/264b.svg")
}
.twa-candle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f56f.svg")
}
.twa-candy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f36c.svg")
}
.twa-canned-food {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f96b.svg")
}
.twa-canoe {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6f6.svg")
}
.twa-capricorn {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2651.svg")
}
.twa-card-file-box {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5c3.svg")
}
.twa-card-index {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4c7.svg")
}
.twa-card-index-dividers {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5c2.svg")
}
.twa-carousel-horse {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3a0.svg")
}
.twa-carp-streamer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f38f.svg")
}
.twa-carpentry-saw {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa9a.svg")
}
.twa-carrot {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f955.svg")
}
.twa-castle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3f0.svg")
}
.twa-cat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f408.svg")
}
.twa-cat-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f431.svg")
}
.twa-cat-with-tears-of-joy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f639.svg")
}
.twa-cat-with-wry-smile {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f63c.svg")
}
.twa-chains {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26d3.svg")
}
.twa-chair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa91.svg")
}
.twa-chart-decreasing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4c9.svg")
}
.twa-chart-increasing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4c8.svg")
}
.twa-chart-increasing-with-yen {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4b9.svg")
}
.twa-check-box-with-check {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2611.svg")
}
.twa-check-mark {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2714.svg")
}
.twa-check-mark-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2705.svg")
}
.twa-cheese-wedge {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9c0.svg")
}
.twa-chequered-flag {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c1.svg")
}
.twa-cherries {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f352.svg")
}
.twa-cherry-blossom {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f338.svg")
}
.twa-chess-pawn {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/265f.svg")
}
.twa-chestnut {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f330.svg")
}
.twa-chicken {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f414.svg")
}
.twa-child {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d2.svg")
}
.twa-child-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d2-1f3ff.svg")
}
.twa-child-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d2-1f3fb.svg")
}
.twa-child-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d2-1f3fe.svg")
}
.twa-child-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d2-1f3fc.svg")
}
.twa-child-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d2-1f3fd.svg")
}
.twa-children-crossing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b8.svg")
}
.twa-chipmunk {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f43f.svg")
}
.twa-chocolate-bar {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f36b.svg")
}
.twa-chopsticks {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f962.svg")
}
.twa-christmas-tree {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f384.svg")
}
.twa-church {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26ea.svg")
}
.twa-cigarette {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6ac.svg")
}
.twa-cinema {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3a6.svg")
}
.twa-circled-m {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/24c2.svg")
}
.twa-circus-tent {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3aa.svg")
}
.twa-cityscape {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3d9.svg")
}
.twa-cityscape-at-dusk {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f306.svg")
}
.twa-cl-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f191.svg")
}
.twa-clamp {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5dc.svg")
}
.twa-clapper-board {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ac.svg")
}
.twa-clapping-hands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44f.svg")
}
.twa-clapping-hands-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44f-1f3ff.svg")
}
.twa-clapping-hands-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44f-1f3fb.svg")
}
.twa-clapping-hands-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44f-1f3fe.svg")
}
.twa-clapping-hands-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44f-1f3fc.svg")
}
.twa-clapping-hands-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44f-1f3fd.svg")
}
.twa-classical-building {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3db.svg")
}
.twa-clinking-beer-mugs {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f37b.svg")
}
.twa-clinking-glasses {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f942.svg")
}
.twa-clipboard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4cb.svg")
}
.twa-clockwise-vertical-arrows {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f503.svg")
}
.twa-closed-book {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4d5.svg")
}
.twa-closed-mailbox-with-lowered-flag {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4ea.svg")
}
.twa-closed-mailbox-with-raised-flag {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4eb.svg")
}
.twa-closed-umbrella {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f302.svg")
}
.twa-cloud {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2601.svg")
}
.twa-cloud-with-lightning {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f329.svg")
}
.twa-cloud-with-lightning-and-rain {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26c8.svg")
}
.twa-cloud-with-rain {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f327.svg")
}
.twa-cloud-with-snow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f328.svg")
}
.twa-clown-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f921.svg")
}
.twa-club-suit {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2663.svg")
}
.twa-clutch-bag {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f45d.svg")
}
.twa-coat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9e5.svg")
}
.twa-cockroach {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fab3.svg")
}
.twa-cocktail-glass {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f378.svg")
}
.twa-coconut {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f965.svg")
}
.twa-coffin {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26b0.svg")
}
.twa-coin {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa99.svg")
}
.twa-cold-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f976.svg")
}
.twa-collision {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4a5.svg")
}
.twa-comet {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2604.svg")
}
.twa-compass {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ed.svg")
}
.twa-computer-disk {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4bd.svg")
}
.twa-computer-mouse {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5b1.svg")
}
.twa-confetti-ball {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f38a.svg")
}
.twa-confounded-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f616.svg")
}
.twa-confused-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f615.svg")
}
.twa-construction {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a7.svg")
}
.twa-construction-worker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477.svg")
}
.twa-construction-worker-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-1f3ff.svg")
}
.twa-construction-worker-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-1f3fb.svg")
}
.twa-construction-worker-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-1f3fe.svg")
}
.twa-construction-worker-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-1f3fc.svg")
}
.twa-construction-worker-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-1f3fd.svg")
}
.twa-control-knobs {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f39b.svg")
}
.twa-convenience-store {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ea.svg")
}
.twa-cook {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f373.svg")
}
.twa-cook-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f373.svg")
}
.twa-cook-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f373.svg")
}
.twa-cook-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f373.svg")
}
.twa-cook-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f373.svg")
}
.twa-cook-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f373.svg")
}
.twa-cooked-rice {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f35a.svg")
}
.twa-cookie {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f36a.svg")
}
.twa-cooking {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f373.svg")
}
.twa-cool-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f192.svg")
}
.twa-copyright {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/a9.svg")
}
.twa-couch-and-lamp {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6cb.svg")
}
.twa-counterclockwise-arrows-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f504.svg")
}
.twa-couple-with-heart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f491.svg")
}
.twa-couple-with-heart-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f491-1f3ff.svg")
}
.twa-couple-with-heart-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f491-1f3fb.svg")
}
.twa-couple-with-heart-man-man {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-2764-fe0f-200d-1f468.svg")
}
.twa-couple-with-heart-man-man-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3ff.svg")
}
.twa-couple-with-heart-man-man-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3fb.svg")
}
.twa-couple-with-heart-man-man-dark-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3fe.svg")
}
.twa-couple-with-heart-man-man-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3fc.svg")
}
.twa-couple-with-heart-man-man-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3fd.svg")
}
.twa-couple-with-heart-man-man-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fb.svg")
}
.twa-couple-with-heart-man-man-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3ff.svg")
}
.twa-couple-with-heart-man-man-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fe.svg")
}
.twa-couple-with-heart-man-man-light-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fc.svg")
}
.twa-couple-with-heart-man-man-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fd.svg")
}
.twa-couple-with-heart-man-man-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fe.svg")
}
.twa-couple-with-heart-man-man-medium-dark-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3ff.svg")
}
.twa-couple-with-heart-man-man-medium-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fb.svg")
}
.twa-couple-with-heart-man-man-medium-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fc.svg")
}
.twa-couple-with-heart-man-man-medium-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fd.svg")
}
.twa-couple-with-heart-man-man-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fc.svg")
}
.twa-couple-with-heart-man-man-medium-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3ff.svg")
}
.twa-couple-with-heart-man-man-medium-light-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fb.svg")
}
.twa-couple-with-heart-man-man-medium-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fe.svg")
}
.twa-couple-with-heart-man-man-medium-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fd.svg")
}
.twa-couple-with-heart-man-man-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fd.svg")
}
.twa-couple-with-heart-man-man-medium-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3ff.svg")
}
.twa-couple-with-heart-man-man-medium-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fb.svg")
}
.twa-couple-with-heart-man-man-medium-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fe.svg")
}
.twa-couple-with-heart-man-man-medium-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fc.svg")
}
.twa-couple-with-heart-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f491-1f3fe.svg")
}
.twa-couple-with-heart-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f491-1f3fc.svg")
}
.twa-couple-with-heart-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f491-1f3fd.svg")
}
.twa-couple-with-heart-person-person-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-2764-fe0f-200d-1f9d1-1f3fb.svg")
}
.twa-couple-with-heart-person-person-dark-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-2764-fe0f-200d-1f9d1-1f3fe.svg")
}
.twa-couple-with-heart-person-person-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-2764-fe0f-200d-1f9d1-1f3fc.svg")
}
.twa-couple-with-heart-person-person-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-2764-fe0f-200d-1f9d1-1f3fd.svg")
}
.twa-couple-with-heart-person-person-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-2764-fe0f-200d-1f9d1-1f3ff.svg")
}
.twa-couple-with-heart-person-person-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-2764-fe0f-200d-1f9d1-1f3fe.svg")
}
.twa-couple-with-heart-person-person-light-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-2764-fe0f-200d-1f9d1-1f3fc.svg")
}
.twa-couple-with-heart-person-person-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-2764-fe0f-200d-1f9d1-1f3fd.svg")
}
.twa-couple-with-heart-person-person-medium-dark-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-2764-fe0f-200d-1f9d1-1f3ff.svg")
}
.twa-couple-with-heart-person-person-medium-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-2764-fe0f-200d-1f9d1-1f3fb.svg")
}
.twa-couple-with-heart-person-person-medium-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-2764-fe0f-200d-1f9d1-1f3fc.svg")
}
.twa-couple-with-heart-person-person-medium-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-2764-fe0f-200d-1f9d1-1f3fd.svg")
}
.twa-couple-with-heart-person-person-medium-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-2764-fe0f-200d-1f9d1-1f3ff.svg")
}
.twa-couple-with-heart-person-person-medium-light-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-2764-fe0f-200d-1f9d1-1f3fb.svg")
}
.twa-couple-with-heart-person-person-medium-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-2764-fe0f-200d-1f9d1-1f3fe.svg")
}
.twa-couple-with-heart-person-person-medium-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-2764-fe0f-200d-1f9d1-1f3fd.svg")
}
.twa-couple-with-heart-person-person-medium-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-2764-fe0f-200d-1f9d1-1f3ff.svg")
}
.twa-couple-with-heart-person-person-medium-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-2764-fe0f-200d-1f9d1-1f3fb.svg")
}
.twa-couple-with-heart-person-person-medium-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-2764-fe0f-200d-1f9d1-1f3fe.svg")
}
.twa-couple-with-heart-person-person-medium-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-2764-fe0f-200d-1f9d1-1f3fc.svg")
}
.twa-couple-with-heart-woman-man {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-2764-fe0f-200d-1f468.svg")
}
.twa-couple-with-heart-woman-man-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3ff.svg")
}
.twa-couple-with-heart-woman-man-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3fb.svg")
}
.twa-couple-with-heart-woman-man-dark-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3fe.svg")
}
.twa-couple-with-heart-woman-man-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3fc.svg")
}
.twa-couple-with-heart-woman-man-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3fd.svg")
}
.twa-couple-with-heart-woman-man-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fb.svg")
}
.twa-couple-with-heart-woman-man-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3ff.svg")
}
.twa-couple-with-heart-woman-man-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fe.svg")
}
.twa-couple-with-heart-woman-man-light-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fc.svg")
}
.twa-couple-with-heart-woman-man-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fd.svg")
}
.twa-couple-with-heart-woman-man-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fe.svg")
}
.twa-couple-with-heart-woman-man-medium-dark-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3ff.svg")
}
.twa-couple-with-heart-woman-man-medium-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fb.svg")
}
.twa-couple-with-heart-woman-man-medium-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fc.svg")
}
.twa-couple-with-heart-woman-man-medium-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fd.svg")
}
.twa-couple-with-heart-woman-man-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fc.svg")
}
.twa-couple-with-heart-woman-man-medium-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3ff.svg")
}
.twa-couple-with-heart-woman-man-medium-light-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fb.svg")
}
.twa-couple-with-heart-woman-man-medium-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fe.svg")
}
.twa-couple-with-heart-woman-man-medium-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fd.svg")
}
.twa-couple-with-heart-woman-man-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fd.svg")
}
.twa-couple-with-heart-woman-man-medium-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3ff.svg")
}
.twa-couple-with-heart-woman-man-medium-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fb.svg")
}
.twa-couple-with-heart-woman-man-medium-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fe.svg")
}
.twa-couple-with-heart-woman-man-medium-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fc.svg")
}
.twa-couple-with-heart-woman-woman {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-2764-fe0f-200d-1f469.svg")
}
.twa-couple-with-heart-woman-woman-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3ff.svg")
}
.twa-couple-with-heart-woman-woman-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3fb.svg")
}
.twa-couple-with-heart-woman-woman-dark-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3fe.svg")
}
.twa-couple-with-heart-woman-woman-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3fc.svg")
}
.twa-couple-with-heart-woman-woman-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3fd.svg")
}
.twa-couple-with-heart-woman-woman-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fb.svg")
}
.twa-couple-with-heart-woman-woman-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3ff.svg")
}
.twa-couple-with-heart-woman-woman-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fe.svg")
}
.twa-couple-with-heart-woman-woman-light-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fc.svg")
}
.twa-couple-with-heart-woman-woman-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fd.svg")
}
.twa-couple-with-heart-woman-woman-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fe.svg")
}
.twa-couple-with-heart-woman-woman-medium-dark-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3ff.svg")
}
.twa-couple-with-heart-woman-woman-medium-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fb.svg")
}
.twa-couple-with-heart-woman-woman-medium-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fc.svg")
}
.twa-couple-with-heart-woman-woman-medium-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fd.svg")
}
.twa-couple-with-heart-woman-woman-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fc.svg")
}
.twa-couple-with-heart-woman-woman-medium-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3ff.svg")
}
.twa-couple-with-heart-woman-woman-medium-light-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fb.svg")
}
.twa-couple-with-heart-woman-woman-medium-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fe.svg")
}
.twa-couple-with-heart-woman-woman-medium-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fd.svg")
}
.twa-couple-with-heart-woman-woman-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fd.svg")
}
.twa-couple-with-heart-woman-woman-medium-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3ff.svg")
}
.twa-couple-with-heart-woman-woman-medium-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fb.svg")
}
.twa-couple-with-heart-woman-woman-medium-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fe.svg")
}
.twa-couple-with-heart-woman-woman-medium-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fc.svg")
}
.twa-cow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f404.svg")
}
.twa-cow-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f42e.svg")
}
.twa-cowboy-hat-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f920.svg")
}
.twa-crab {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f980.svg")
}
.twa-crayon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f58d.svg")
}
.twa-credit-card {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4b3.svg")
}
.twa-crescent-moon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f319.svg")
}
.twa-cricket {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f997.svg")
}
.twa-cricket-game {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cf.svg")
}
.twa-crocodile {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f40a.svg")
}
.twa-croissant {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f950.svg")
}
.twa-cross-mark {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/274c.svg")
}
.twa-cross-mark-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/274e.svg")
}
.twa-crossed-fingers {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91e.svg")
}
.twa-crossed-fingers-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91e-1f3ff.svg")
}
.twa-crossed-fingers-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91e-1f3fb.svg")
}
.twa-crossed-fingers-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91e-1f3fe.svg")
}
.twa-crossed-fingers-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91e-1f3fc.svg")
}
.twa-crossed-fingers-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91e-1f3fd.svg")
}
.twa-crossed-flags {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f38c.svg")
}
.twa-crossed-swords {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2694.svg")
}
.twa-crown {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f451.svg")
}
.twa-crying-cat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f63f.svg")
}
.twa-crying-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f622.svg")
}
.twa-crystal-ball {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f52e.svg")
}
.twa-cucumber {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f952.svg")
}
.twa-cup-with-straw {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f964.svg")
}
.twa-cupcake {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9c1.svg")
}
.twa-curling-stone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f94c.svg")
}
.twa-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b1.svg")
}
.twa-curly-loop {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/27b0.svg")
}
.twa-currency-exchange {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4b1.svg")
}
.twa-curry-rice {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f35b.svg")
}
.twa-custard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f36e.svg")
}
.twa-customs {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6c3.svg")
}
.twa-cut-of-meat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f969.svg")
}
.twa-cyclone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f300.svg")
}
.twa-dagger {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5e1.svg")
}
.twa-dango {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f361.svg")
}
.twa-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ff.svg")
}
.twa-dashing-away {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4a8.svg")
}
.twa-deaf-man {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-200d-2642-fe0f.svg")
}
.twa-deaf-man-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-1f3ff-200d-2642-fe0f.svg")
}
.twa-deaf-man-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-1f3fb-200d-2642-fe0f.svg")
}
.twa-deaf-man-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-1f3fe-200d-2642-fe0f.svg")
}
.twa-deaf-man-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-1f3fc-200d-2642-fe0f.svg")
}
.twa-deaf-man-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-1f3fd-200d-2642-fe0f.svg")
}
.twa-deaf-person {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf.svg")
}
.twa-deaf-person-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-1f3ff.svg")
}
.twa-deaf-person-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-1f3fb.svg")
}
.twa-deaf-person-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-1f3fe.svg")
}
.twa-deaf-person-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-1f3fc.svg")
}
.twa-deaf-person-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-1f3fd.svg")
}
.twa-deaf-woman {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-200d-2640-fe0f.svg")
}
.twa-deaf-woman-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-1f3ff-200d-2640-fe0f.svg")
}
.twa-deaf-woman-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-1f3fb-200d-2640-fe0f.svg")
}
.twa-deaf-woman-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-1f3fe-200d-2640-fe0f.svg")
}
.twa-deaf-woman-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-1f3fc-200d-2640-fe0f.svg")
}
.twa-deaf-woman-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cf-1f3fd-200d-2640-fe0f.svg")
}
.twa-deciduous-tree {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f333.svg")
}
.twa-deer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f98c.svg")
}
.twa-delivery-truck {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f69a.svg")
}
.twa-department-store {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ec.svg")
}
.twa-derelict-house {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3da.svg")
}
.twa-desert {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3dc.svg")
}
.twa-desert-island {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3dd.svg")
}
.twa-desktop-computer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5a5.svg")
}
.twa-detective {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575.svg")
}
.twa-detective-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-1f3ff.svg")
}
.twa-detective-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-1f3fb.svg")
}
.twa-detective-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-1f3fe.svg")
}
.twa-detective-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-1f3fc.svg")
}
.twa-detective-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-1f3fd.svg")
}
.twa-diamond-suit {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2666.svg")
}
.twa-diamond-with-a-dot {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4a0.svg")
}
.twa-dim-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f505.svg")
}
.twa-disappointed-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f61e.svg")
}
.twa-disguised-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f978.svg")
}
.twa-divide {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2797.svg")
}
.twa-diving-mask {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93f.svg")
}
.twa-diya-lamp {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa94.svg")
}
.twa-dizzy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4ab.svg")
}
.twa-dna {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ec.svg")
}
.twa-dodo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9a4.svg")
}
.twa-dog {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f415.svg")
}
.twa-dog-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f436.svg")
}
.twa-dollar-banknote {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4b5.svg")
}
.twa-dolphin {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f42c.svg")
}
.twa-door {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6aa.svg")
}
.twa-dotted-six-pointed-star {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f52f.svg")
}
.twa-double-curly-loop {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/27bf.svg")
}
.twa-double-exclamation-mark {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/203c.svg")
}
.twa-doughnut {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f369.svg")
}
.twa-dove {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f54a.svg")
}
.twa-down-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2b07.svg")
}
.twa-down-left-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2199.svg")
}
.twa-down-right-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2198.svg")
}
.twa-downcast-face-with-sweat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f613.svg")
}
.twa-downwards-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f53d.svg")
}
.twa-dragon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f409.svg")
}
.twa-dragon-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f432.svg")
}
.twa-dress {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f457.svg")
}
.twa-drooling-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f924.svg")
}
.twa-drop-of-blood {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa78.svg")
}
.twa-droplet {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4a7.svg")
}
.twa-drum {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f941.svg")
}
.twa-duck {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f986.svg")
}
.twa-dumpling {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f95f.svg")
}
.twa-dvd {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4c0.svg")
}
.twa-e-mail {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4e7.svg")
}
.twa-eagle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f985.svg")
}
.twa-ear {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f442.svg")
}
.twa-ear-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f442-1f3ff.svg")
}
.twa-ear-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f442-1f3fb.svg")
}
.twa-ear-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f442-1f3fe.svg")
}
.twa-ear-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f442-1f3fc.svg")
}
.twa-ear-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f442-1f3fd.svg")
}
.twa-ear-of-corn {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f33d.svg")
}
.twa-ear-with-hearing-aid {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9bb.svg")
}
.twa-ear-with-hearing-aid-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9bb-1f3ff.svg")
}
.twa-ear-with-hearing-aid-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9bb-1f3fb.svg")
}
.twa-ear-with-hearing-aid-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9bb-1f3fe.svg")
}
.twa-ear-with-hearing-aid-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9bb-1f3fc.svg")
}
.twa-ear-with-hearing-aid-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9bb-1f3fd.svg")
}
.twa-egg {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f95a.svg")
}
.twa-eggplant {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f346.svg")
}
.twa-eight-o-clock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f557.svg")
}
.twa-eight-pointed-star {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2734.svg")
}
.twa-eight-spoked-asterisk {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2733.svg")
}
.twa-eight-thirty {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f563.svg")
}
.twa-eject-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/23cf.svg")
}
.twa-electric-plug {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f50c.svg")
}
.twa-elephant {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f418.svg")
}
.twa-elevator {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6d7.svg")
}
.twa-eleven-o-clock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f55a.svg")
}
.twa-eleven-thirty {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f566.svg")
}
.twa-elf {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd.svg")
}
.twa-elf-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-1f3ff.svg")
}
.twa-elf-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-1f3fb.svg")
}
.twa-elf-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-1f3fe.svg")
}
.twa-elf-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-1f3fc.svg")
}
.twa-elf-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-1f3fd.svg")
}
.twa-end-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f51a.svg")
}
.twa-envelope {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2709.svg")
}
.twa-envelope-with-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4e9.svg")
}
.twa-euro-banknote {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4b6.svg")
}
.twa-evergreen-tree {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f332.svg")
}
.twa-ewe {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f411.svg")
}
.twa-exclamation-question-mark {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2049.svg")
}
.twa-exploding-head {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f92f.svg")
}
.twa-expressionless-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f611.svg")
}
.twa-eye {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f441.svg")
}
.twa-eye-in-speech-bubble {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f441-200d-1f5e8.svg")
}
.twa-eyes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f440.svg")
}
.twa-face-blowing-a-kiss {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f618.svg")
}
.twa-face-exhaling {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f62e-200d-1f4a8.svg")
}
.twa-face-in-clouds {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f636-200d-1f32b-fe0f.svg")
}
.twa-face-savoring-food {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f60b.svg")
}
.twa-face-screaming-in-fear {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f631.svg")
}
.twa-face-vomiting {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f92e.svg")
}
.twa-face-with-hand-over-mouth {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f92d.svg")
}
.twa-face-with-head-bandage {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f915.svg")
}
.twa-face-with-medical-mask {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f637.svg")
}
.twa-face-with-monocle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d0.svg")
}
.twa-face-with-open-mouth {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f62e.svg")
}
.twa-face-with-raised-eyebrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f928.svg")
}
.twa-face-with-rolling-eyes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f644.svg")
}
.twa-face-with-spiral-eyes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f635-200d-1f4ab.svg")
}
.twa-face-with-steam-from-nose {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f624.svg")
}
.twa-face-with-symbols-on-mouth {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f92c.svg")
}
.twa-face-with-tears-of-joy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f602.svg")
}
.twa-face-with-thermometer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f912.svg")
}
.twa-face-with-tongue {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f61b.svg")
}
.twa-face-without-mouth {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f636.svg")
}
.twa-factory {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ed.svg")
}
.twa-factory-worker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f3ed.svg")
}
.twa-factory-worker-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f3ed.svg")
}
.twa-factory-worker-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f3ed.svg")
}
.twa-factory-worker-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f3ed.svg")
}
.twa-factory-worker-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f3ed.svg")
}
.twa-factory-worker-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f3ed.svg")
}
.twa-fairy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da.svg")
}
.twa-fairy-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-1f3ff.svg")
}
.twa-fairy-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-1f3fb.svg")
}
.twa-fairy-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-1f3fe.svg")
}
.twa-fairy-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-1f3fc.svg")
}
.twa-fairy-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-1f3fd.svg")
}
.twa-falafel {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9c6.svg")
}
.twa-fallen-leaf {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f342.svg")
}
.twa-family {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46a.svg")
}
.twa-family-man-boy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f466.svg")
}
.twa-family-man-boy-boy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f466-200d-1f466.svg")
}
.twa-family-man-girl {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f467.svg")
}
.twa-family-man-girl-boy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f467-200d-1f466.svg")
}
.twa-family-man-girl-girl {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f467-200d-1f467.svg")
}
.twa-family-man-man-boy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f468-200d-1f466.svg")
}
.twa-family-man-man-boy-boy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f468-200d-1f466-200d-1f466.svg")
}
.twa-family-man-man-girl {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f468-200d-1f467.svg")
}
.twa-family-man-man-girl-boy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f468-200d-1f467-200d-1f466.svg")
}
.twa-family-man-man-girl-girl {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f468-200d-1f467-200d-1f467.svg")
}
.twa-family-man-woman-boy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f469-200d-1f466.svg")
}
.twa-family-man-woman-boy-boy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f469-200d-1f466-200d-1f466.svg")
}
.twa-family-man-woman-girl {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f469-200d-1f467.svg")
}
.twa-family-man-woman-girl-boy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f469-200d-1f467-200d-1f466.svg")
}
.twa-family-man-woman-girl-girl {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f469-200d-1f467-200d-1f467.svg")
}
.twa-family-woman-boy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f466.svg")
}
.twa-family-woman-boy-boy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f466-200d-1f466.svg")
}
.twa-family-woman-girl {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f467.svg")
}
.twa-family-woman-girl-boy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f467-200d-1f466.svg")
}
.twa-family-woman-girl-girl {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f467-200d-1f467.svg")
}
.twa-family-woman-woman-boy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f469-200d-1f466.svg")
}
.twa-family-woman-woman-boy-boy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f469-200d-1f466-200d-1f466.svg")
}
.twa-family-woman-woman-girl {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f469-200d-1f467.svg")
}
.twa-family-woman-woman-girl-boy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f469-200d-1f467-200d-1f466.svg")
}
.twa-family-woman-woman-girl-girl {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f469-200d-1f467-200d-1f467.svg")
}
.twa-farmer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f33e.svg")
}
.twa-farmer-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f33e.svg")
}
.twa-farmer-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f33e.svg")
}
.twa-farmer-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f33e.svg")
}
.twa-farmer-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f33e.svg")
}
.twa-farmer-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f33e.svg")
}
.twa-fast-down-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/23ec.svg")
}
.twa-fast-forward-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/23e9.svg")
}
.twa-fast-reverse-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/23ea.svg")
}
.twa-fast-up-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/23eb.svg")
}
.twa-fax-machine {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4e0.svg")
}
.twa-fearful-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f628.svg")
}
.twa-feather {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fab6.svg")
}
.twa-female-sign {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2640.svg")
}
.twa-ferris-wheel {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3a1.svg")
}
.twa-ferry {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f4.svg")
}
.twa-field-hockey {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3d1.svg")
}
.twa-file-cabinet {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5c4.svg")
}
.twa-file-folder {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4c1.svg")
}
.twa-film-frames {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f39e.svg")
}
.twa-film-projector {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4fd.svg")
}
.twa-fire {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f525.svg")
}
.twa-fire-engine {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f692.svg")
}
.twa-fire-extinguisher {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ef.svg")
}
.twa-firecracker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9e8.svg")
}
.twa-firefighter {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f692.svg")
}
.twa-firefighter-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f692.svg")
}
.twa-firefighter-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f692.svg")
}
.twa-firefighter-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f692.svg")
}
.twa-firefighter-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f692.svg")
}
.twa-firefighter-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f692.svg")
}
.twa-fireworks {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f386.svg")
}
.twa-first-quarter-moon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f313.svg")
}
.twa-first-quarter-moon-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f31b.svg")
}
.twa-fish {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f41f.svg")
}
.twa-fish-cake-with-swirl {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f365.svg")
}
.twa-fishing-pole {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3a3.svg")
}
.twa-five-o-clock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f554.svg")
}
.twa-five-thirty {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f560.svg")
}
.twa-flag-afghanistan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1eb.svg")
}
.twa-flag-albania {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1f1.svg")
}
.twa-flag-algeria {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e9-1f1ff.svg")
}
.twa-flag-american-samoa {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1f8.svg")
}
.twa-flag-andorra {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1e9.svg")
}
.twa-flag-angola {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1f4.svg")
}
.twa-flag-anguilla {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1ee.svg")
}
.twa-flag-antarctica {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1f6.svg")
}
.twa-flag-antigua-barbuda {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1ec.svg")
}
.twa-flag-argentina {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1f7.svg")
}
.twa-flag-armenia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1f2.svg")
}
.twa-flag-aruba {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1fc.svg")
}
.twa-flag-ascension-island {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1e8.svg")
}
.twa-flag-australia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1fa.svg")
}
.twa-flag-austria {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1f9.svg")
}
.twa-flag-azerbaijan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1ff.svg")
}
.twa-flag-bahamas {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1f8.svg")
}
.twa-flag-bahrain {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1ed.svg")
}
.twa-flag-bangladesh {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1e9.svg")
}
.twa-flag-barbados {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1e7.svg")
}
.twa-flag-belarus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1fe.svg")
}
.twa-flag-belgium {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1ea.svg")
}
.twa-flag-belize {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1ff.svg")
}
.twa-flag-benin {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1ef.svg")
}
.twa-flag-bermuda {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1f2.svg")
}
.twa-flag-bhutan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1f9.svg")
}
.twa-flag-bolivia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1f4.svg")
}
.twa-flag-bosnia-herzegovina {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1e6.svg")
}
.twa-flag-botswana {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1fc.svg")
}
.twa-flag-bouvet-island {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1fb.svg")
}
.twa-flag-brazil {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1f7.svg")
}
.twa-flag-british-indian-ocean-territory {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ee-1f1f4.svg")
}
.twa-flag-british-virgin-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fb-1f1ec.svg")
}
.twa-flag-brunei {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1f3.svg")
}
.twa-flag-bulgaria {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1ec.svg")
}
.twa-flag-burkina-faso {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1eb.svg")
}
.twa-flag-burundi {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1ee.svg")
}
.twa-flag-c-te-d-ivoire {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1ee.svg")
}
.twa-flag-cambodia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f0-1f1ed.svg")
}
.twa-flag-cameroon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1f2.svg")
}
.twa-flag-canada {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1e6.svg")
}
.twa-flag-canary-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ee-1f1e8.svg")
}
.twa-flag-cape-verde {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1fb.svg")
}
.twa-flag-caribbean-netherlands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1f6.svg")
}
.twa-flag-cayman-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f0-1f1fe.svg")
}
.twa-flag-central-african-republic {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1eb.svg")
}
.twa-flag-ceuta-melilla {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ea-1f1e6.svg")
}
.twa-flag-chad {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1e9.svg")
}
.twa-flag-chile {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1f1.svg")
}
.twa-flag-china {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1f3.svg")
}
.twa-flag-christmas-island {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1fd.svg")
}
.twa-flag-clipperton-island {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1f5.svg")
}
.twa-flag-cocos-keeling-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1e8.svg")
}
.twa-flag-colombia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1f4.svg")
}
.twa-flag-comoros {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f0-1f1f2.svg")
}
.twa-flag-congo-brazzaville {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1ec.svg")
}
.twa-flag-congo-kinshasa {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1e9.svg")
}
.twa-flag-cook-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1f0.svg")
}
.twa-flag-costa-rica {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1f7.svg")
}
.twa-flag-croatia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ed-1f1f7.svg")
}
.twa-flag-cuba {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1fa.svg")
}
.twa-flag-cura-ao {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1fc.svg")
}
.twa-flag-cyprus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1fe.svg")
}
.twa-flag-czechia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1ff.svg")
}
.twa-flag-denmark {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e9-1f1f0.svg")
}
.twa-flag-diego-garcia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e9-1f1ec.svg")
}
.twa-flag-djibouti {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e9-1f1ef.svg")
}
.twa-flag-dominica {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e9-1f1f2.svg")
}
.twa-flag-dominican-republic {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e9-1f1f4.svg")
}
.twa-flag-ecuador {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ea-1f1e8.svg")
}
.twa-flag-egypt {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ea-1f1ec.svg")
}
.twa-flag-el-salvador {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1fb.svg")
}
.twa-flag-england {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.svg")
}
.twa-flag-equatorial-guinea {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1f6.svg")
}
.twa-flag-eritrea {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ea-1f1f7.svg")
}
.twa-flag-estonia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ea-1f1ea.svg")
}
.twa-flag-eswatini {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1ff.svg")
}
.twa-flag-ethiopia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ea-1f1f9.svg")
}
.twa-flag-european-union {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ea-1f1fa.svg")
}
.twa-flag-falkland-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1eb-1f1f0.svg")
}
.twa-flag-faroe-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1eb-1f1f4.svg")
}
.twa-flag-fiji {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1eb-1f1ef.svg")
}
.twa-flag-finland {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1eb-1f1ee.svg")
}
.twa-flag-france {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1eb-1f1f7.svg")
}
.twa-flag-french-guiana {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1eb.svg")
}
.twa-flag-french-polynesia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f5-1f1eb.svg")
}
.twa-flag-french-southern-territories {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1eb.svg")
}
.twa-flag-gabon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1e6.svg")
}
.twa-flag-gambia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1f2.svg")
}
.twa-flag-georgia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1ea.svg")
}
.twa-flag-germany {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e9-1f1ea.svg")
}
.twa-flag-ghana {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1ed.svg")
}
.twa-flag-gibraltar {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1ee.svg")
}
.twa-flag-greece {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1f7.svg")
}
.twa-flag-greenland {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1f1.svg")
}
.twa-flag-grenada {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1e9.svg")
}
.twa-flag-guadeloupe {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1f5.svg")
}
.twa-flag-guam {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1fa.svg")
}
.twa-flag-guatemala {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1f9.svg")
}
.twa-flag-guernsey {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1ec.svg")
}
.twa-flag-guinea {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1f3.svg")
}
.twa-flag-guinea-bissau {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1fc.svg")
}
.twa-flag-guyana {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1fe.svg")
}
.twa-flag-haiti {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ed-1f1f9.svg")
}
.twa-flag-heard-mcdonald-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ed-1f1f2.svg")
}
.twa-flag-honduras {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ed-1f1f3.svg")
}
.twa-flag-hong-kong-sar-china {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ed-1f1f0.svg")
}
.twa-flag-hungary {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ed-1f1fa.svg")
}
.twa-flag-iceland {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ee-1f1f8.svg")
}
.twa-flag-in-hole {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f3.svg")
}
.twa-flag-india {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ee-1f1f3.svg")
}
.twa-flag-indonesia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ee-1f1e9.svg")
}
.twa-flag-iran {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ee-1f1f7.svg")
}
.twa-flag-iraq {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ee-1f1f6.svg")
}
.twa-flag-ireland {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ee-1f1ea.svg")
}
.twa-flag-isle-of-man {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ee-1f1f2.svg")
}
.twa-flag-israel {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ee-1f1f1.svg")
}
.twa-flag-italy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ee-1f1f9.svg")
}
.twa-flag-jamaica {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ef-1f1f2.svg")
}
.twa-flag-japan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ef-1f1f5.svg")
}
.twa-flag-jersey {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ef-1f1ea.svg")
}
.twa-flag-jordan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ef-1f1f4.svg")
}
.twa-flag-kazakhstan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f0-1f1ff.svg")
}
.twa-flag-kenya {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f0-1f1ea.svg")
}
.twa-flag-kiribati {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f0-1f1ee.svg")
}
.twa-flag-kosovo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fd-1f1f0.svg")
}
.twa-flag-kuwait {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f0-1f1fc.svg")
}
.twa-flag-kyrgyzstan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f0-1f1ec.svg")
}
.twa-flag-land-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1fd.svg")
}
.twa-flag-laos {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f1-1f1e6.svg")
}
.twa-flag-latvia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f1-1f1fb.svg")
}
.twa-flag-lebanon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f1-1f1e7.svg")
}
.twa-flag-lesotho {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f1-1f1f8.svg")
}
.twa-flag-liberia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f1-1f1f7.svg")
}
.twa-flag-libya {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f1-1f1fe.svg")
}
.twa-flag-liechtenstein {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f1-1f1ee.svg")
}
.twa-flag-lithuania {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f1-1f1f9.svg")
}
.twa-flag-luxembourg {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f1-1f1fa.svg")
}
.twa-flag-macao-sar-china {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1f4.svg")
}
.twa-flag-madagascar {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1ec.svg")
}
.twa-flag-malawi {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1fc.svg")
}
.twa-flag-malaysia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1fe.svg")
}
.twa-flag-maldives {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1fb.svg")
}
.twa-flag-mali {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1f1.svg")
}
.twa-flag-malta {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1f9.svg")
}
.twa-flag-marshall-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1ed.svg")
}
.twa-flag-martinique {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1f6.svg")
}
.twa-flag-mauritania {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1f7.svg")
}
.twa-flag-mauritius {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1fa.svg")
}
.twa-flag-mayotte {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fe-1f1f9.svg")
}
.twa-flag-mexico {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1fd.svg")
}
.twa-flag-micronesia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1eb-1f1f2.svg")
}
.twa-flag-moldova {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1e9.svg")
}
.twa-flag-monaco {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1e8.svg")
}
.twa-flag-mongolia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1f3.svg")
}
.twa-flag-montenegro {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1ea.svg")
}
.twa-flag-montserrat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1f8.svg")
}
.twa-flag-morocco {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1e6.svg")
}
.twa-flag-mozambique {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1ff.svg")
}
.twa-flag-myanmar-burma {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1f2.svg")
}
.twa-flag-namibia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f3-1f1e6.svg")
}
.twa-flag-nauru {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f3-1f1f7.svg")
}
.twa-flag-nepal {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f3-1f1f5.svg")
}
.twa-flag-netherlands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f3-1f1f1.svg")
}
.twa-flag-new-caledonia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f3-1f1e8.svg")
}
.twa-flag-new-zealand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f3-1f1ff.svg")
}
.twa-flag-nicaragua {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f3-1f1ee.svg")
}
.twa-flag-niger {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f3-1f1ea.svg")
}
.twa-flag-nigeria {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f3-1f1ec.svg")
}
.twa-flag-niue {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f3-1f1fa.svg")
}
.twa-flag-norfolk-island {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f3-1f1eb.svg")
}
.twa-flag-north-korea {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f0-1f1f5.svg")
}
.twa-flag-north-macedonia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1f0.svg")
}
.twa-flag-northern-mariana-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1f5.svg")
}
.twa-flag-norway {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f3-1f1f4.svg")
}
.twa-flag-oman {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f4-1f1f2.svg")
}
.twa-flag-pakistan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f5-1f1f0.svg")
}
.twa-flag-palau {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f5-1f1fc.svg")
}
.twa-flag-palestinian-territories {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f5-1f1f8.svg")
}
.twa-flag-panama {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f5-1f1e6.svg")
}
.twa-flag-papua-new-guinea {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f5-1f1ec.svg")
}
.twa-flag-paraguay {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f5-1f1fe.svg")
}
.twa-flag-peru {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f5-1f1ea.svg")
}
.twa-flag-philippines {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f5-1f1ed.svg")
}
.twa-flag-pitcairn-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f5-1f1f3.svg")
}
.twa-flag-poland {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f5-1f1f1.svg")
}
.twa-flag-portugal {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f5-1f1f9.svg")
}
.twa-flag-puerto-rico {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f5-1f1f7.svg")
}
.twa-flag-qatar {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f6-1f1e6.svg")
}
.twa-flag-r-union {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f7-1f1ea.svg")
}
.twa-flag-romania {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f7-1f1f4.svg")
}
.twa-flag-russia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f7-1f1fa.svg")
}
.twa-flag-rwanda {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f7-1f1fc.svg")
}
.twa-flag-s-o-tom-pr-ncipe {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1f9.svg")
}
.twa-flag-samoa {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fc-1f1f8.svg")
}
.twa-flag-san-marino {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1f2.svg")
}
.twa-flag-saudi-arabia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1e6.svg")
}
.twa-flag-scotland {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.svg")
}
.twa-flag-senegal {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1f3.svg")
}
.twa-flag-serbia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f7-1f1f8.svg")
}
.twa-flag-seychelles {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1e8.svg")
}
.twa-flag-sierra-leone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1f1.svg")
}
.twa-flag-singapore {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1ec.svg")
}
.twa-flag-sint-maarten {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1fd.svg")
}
.twa-flag-slovakia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1f0.svg")
}
.twa-flag-slovenia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1ee.svg")
}
.twa-flag-solomon-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1e7.svg")
}
.twa-flag-somalia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1f4.svg")
}
.twa-flag-south-africa {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ff-1f1e6.svg")
}
.twa-flag-south-georgia-south-sandwich-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1f8.svg")
}
.twa-flag-south-korea {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f0-1f1f7.svg")
}
.twa-flag-south-sudan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1f8.svg")
}
.twa-flag-spain {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ea-1f1f8.svg")
}
.twa-flag-sri-lanka {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f1-1f1f0.svg")
}
.twa-flag-st-barth-lemy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7-1f1f1.svg")
}
.twa-flag-st-helena {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1ed.svg")
}
.twa-flag-st-kitts-nevis {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f0-1f1f3.svg")
}
.twa-flag-st-lucia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f1-1f1e8.svg")
}
.twa-flag-st-martin {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2-1f1eb.svg")
}
.twa-flag-st-pierre-miquelon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f5-1f1f2.svg")
}
.twa-flag-st-vincent-grenadines {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fb-1f1e8.svg")
}
.twa-flag-sudan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1e9.svg")
}
.twa-flag-suriname {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1f7.svg")
}
.twa-flag-svalbard-jan-mayen {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1ef.svg")
}
.twa-flag-sweden {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1ea.svg")
}
.twa-flag-switzerland {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1ed.svg")
}
.twa-flag-syria {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8-1f1fe.svg")
}
.twa-flag-taiwan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1fc.svg")
}
.twa-flag-tajikistan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1ef.svg")
}
.twa-flag-tanzania {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1ff.svg")
}
.twa-flag-thailand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1ed.svg")
}
.twa-flag-timor-leste {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1f1.svg")
}
.twa-flag-togo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1ec.svg")
}
.twa-flag-tokelau {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1f0.svg")
}
.twa-flag-tonga {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1f4.svg")
}
.twa-flag-trinidad-tobago {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1f9.svg")
}
.twa-flag-tristan-da-cunha {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1e6.svg")
}
.twa-flag-tunisia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1f3.svg")
}
.twa-flag-turkey {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1f7.svg")
}
.twa-flag-turkmenistan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1f2.svg")
}
.twa-flag-turks-caicos-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1e8.svg")
}
.twa-flag-tuvalu {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9-1f1fb.svg")
}
.twa-flag-u-s-outlying-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fa-1f1f2.svg")
}
.twa-flag-u-s-virgin-islands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fb-1f1ee.svg")
}
.twa-flag-uganda {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fa-1f1ec.svg")
}
.twa-flag-ukraine {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fa-1f1e6.svg")
}
.twa-flag-united-arab-emirates {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1ea.svg")
}
.twa-flag-united-kingdom {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1e7.svg")
}
.twa-flag-united-nations {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fa-1f1f3.svg")
}
.twa-flag-united-states {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fa-1f1f8.svg")
}
.twa-flag-uruguay {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fa-1f1fe.svg")
}
.twa-flag-uzbekistan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fa-1f1ff.svg")
}
.twa-flag-vanuatu {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fb-1f1fa.svg")
}
.twa-flag-vatican-city {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fb-1f1e6.svg")
}
.twa-flag-venezuela {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fb-1f1ea.svg")
}
.twa-flag-vietnam {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fb-1f1f3.svg")
}
.twa-flag-wales {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.svg")
}
.twa-flag-wallis-futuna {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fc-1f1eb.svg")
}
.twa-flag-western-sahara {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ea-1f1ed.svg")
}
.twa-flag-yemen {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fe-1f1ea.svg")
}
.twa-flag-zambia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ff-1f1f2.svg")
}
.twa-flag-zimbabwe {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ff-1f1fc.svg")
}
.twa-flamingo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9a9.svg")
}
.twa-flashlight {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f526.svg")
}
.twa-flat-shoe {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f97f.svg")
}
.twa-flatbread {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fad3.svg")
}
.twa-fleur-de-lis {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/269c.svg")
}
.twa-flexed-biceps {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4aa.svg")
}
.twa-flexed-biceps-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4aa-1f3ff.svg")
}
.twa-flexed-biceps-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4aa-1f3fb.svg")
}
.twa-flexed-biceps-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4aa-1f3fe.svg")
}
.twa-flexed-biceps-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4aa-1f3fc.svg")
}
.twa-flexed-biceps-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4aa-1f3fd.svg")
}
.twa-floppy-disk {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4be.svg")
}
.twa-flower-playing-cards {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3b4.svg")
}
.twa-flushed-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f633.svg")
}
.twa-fly {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fab0.svg")
}
.twa-flying-disc {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f94f.svg")
}
.twa-flying-saucer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6f8.svg")
}
.twa-fog {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f32b.svg")
}
.twa-foggy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f301.svg")
}
.twa-folded-hands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64f.svg")
}
.twa-folded-hands-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64f-1f3ff.svg")
}
.twa-folded-hands-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64f-1f3fb.svg")
}
.twa-folded-hands-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64f-1f3fe.svg")
}
.twa-folded-hands-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64f-1f3fc.svg")
}
.twa-folded-hands-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64f-1f3fd.svg")
}
.twa-fondue {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fad5.svg")
}
.twa-foot {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b6.svg")
}
.twa-foot-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b6-1f3ff.svg")
}
.twa-foot-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b6-1f3fb.svg")
}
.twa-foot-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b6-1f3fe.svg")
}
.twa-foot-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b6-1f3fc.svg")
}
.twa-foot-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b6-1f3fd.svg")
}
.twa-footprints {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f463.svg")
}
.twa-fork-and-knife {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f374.svg")
}
.twa-fork-and-knife-with-plate {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f37d.svg")
}
.twa-fortune-cookie {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f960.svg")
}
.twa-fountain {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f2.svg")
}
.twa-fountain-pen {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f58b.svg")
}
.twa-four-leaf-clover {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f340.svg")
}
.twa-four-o-clock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f553.svg")
}
.twa-four-thirty {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f55f.svg")
}
.twa-fox {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f98a.svg")
}
.twa-framed-picture {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5bc.svg")
}
.twa-free-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f193.svg")
}
.twa-french-fries {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f35f.svg")
}
.twa-fried-shrimp {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f364.svg")
}
.twa-frog {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f438.svg")
}
.twa-front-facing-baby-chick {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f425.svg")
}
.twa-frowning-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2639.svg")
}
.twa-frowning-face-with-open-mouth {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f626.svg")
}
.twa-fuel-pump {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26fd.svg")
}
.twa-full-moon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f315.svg")
}
.twa-full-moon-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f31d.svg")
}
.twa-funeral-urn {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26b1.svg")
}
.twa-game-die {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3b2.svg")
}
.twa-garlic {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9c4.svg")
}
.twa-gear {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2699.svg")
}
.twa-gem-stone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f48e.svg")
}
.twa-gemini {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/264a.svg")
}
.twa-genie {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9de.svg")
}
.twa-ghost {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f47b.svg")
}
.twa-giraffe {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f992.svg")
}
.twa-girl {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f467.svg")
}
.twa-girl-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f467-1f3ff.svg")
}
.twa-girl-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f467-1f3fb.svg")
}
.twa-girl-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f467-1f3fe.svg")
}
.twa-girl-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f467-1f3fc.svg")
}
.twa-girl-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f467-1f3fd.svg")
}
.twa-glass-of-milk {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f95b.svg")
}
.twa-glasses {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f453.svg")
}
.twa-globe-showing-americas {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f30e.svg")
}
.twa-globe-showing-asia-australia {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f30f.svg")
}
.twa-globe-showing-europe-africa {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f30d.svg")
}
.twa-globe-with-meridians {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f310.svg")
}
.twa-gloves {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9e4.svg")
}
.twa-glowing-star {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f31f.svg")
}
.twa-goal-net {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f945.svg")
}
.twa-goat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f410.svg")
}
.twa-goblin {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f47a.svg")
}
.twa-goggles {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f97d.svg")
}
.twa-gorilla {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f98d.svg")
}
.twa-graduation-cap {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f393.svg")
}
.twa-grapes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f347.svg")
}
.twa-green-apple {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f34f.svg")
}
.twa-green-book {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4d7.svg")
}
.twa-green-circle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f7e2.svg")
}
.twa-green-heart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f49a.svg")
}
.twa-green-salad {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f957.svg")
}
.twa-green-square {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f7e9.svg")
}
.twa-grimacing-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f62c.svg")
}
.twa-grinning-cat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f63a.svg")
}
.twa-grinning-cat-with-smiling-eyes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f638.svg")
}
.twa-grinning-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f600.svg")
}
.twa-grinning-face-with-big-eyes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f603.svg")
}
.twa-grinning-face-with-smiling-eyes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f604.svg")
}
.twa-grinning-face-with-sweat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f605.svg")
}
.twa-grinning-squinting-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f606.svg")
}
.twa-growing-heart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f497.svg")
}
.twa-guard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482.svg")
}
.twa-guard-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-1f3ff.svg")
}
.twa-guard-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-1f3fb.svg")
}
.twa-guard-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-1f3fe.svg")
}
.twa-guard-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-1f3fc.svg")
}
.twa-guard-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-1f3fd.svg")
}
.twa-guide-dog {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ae.svg")
}
.twa-guitar {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3b8.svg")
}
.twa-hamburger {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f354.svg")
}
.twa-hammer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f528.svg")
}
.twa-hammer-and-pick {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2692.svg")
}
.twa-hammer-and-wrench {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6e0.svg")
}
.twa-hamster {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f439.svg")
}
.twa-hand-with-fingers-splayed {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f590.svg")
}
.twa-hand-with-fingers-splayed-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f590-1f3ff.svg")
}
.twa-hand-with-fingers-splayed-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f590-1f3fb.svg")
}
.twa-hand-with-fingers-splayed-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f590-1f3fe.svg")
}
.twa-hand-with-fingers-splayed-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f590-1f3fc.svg")
}
.twa-hand-with-fingers-splayed-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f590-1f3fd.svg")
}
.twa-handbag {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f45c.svg")
}
.twa-handshake {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91d.svg")
}
.twa-hatching-chick {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f423.svg")
}
.twa-headphone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3a7.svg")
}
.twa-headstone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1faa6.svg")
}
.twa-health-worker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-2695-fe0f.svg")
}
.twa-health-worker-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-2695-fe0f.svg")
}
.twa-health-worker-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-2695-fe0f.svg")
}
.twa-health-worker-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-2695-fe0f.svg")
}
.twa-health-worker-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-2695-fe0f.svg")
}
.twa-health-worker-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-2695-fe0f.svg")
}
.twa-hear-no-evil-monkey {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f649.svg")
}
.twa-heart-decoration {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f49f.svg")
}
.twa-heart-exclamation {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2763.svg")
}
.twa-heart-on-fire {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2764-fe0f-200d-1f525.svg")
}
.twa-heart-suit {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2665.svg")
}
.twa-heart-with-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f498.svg")
}
.twa-heart-with-ribbon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f49d.svg")
}
.twa-heavy-dollar-sign {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4b2.svg")
}
.twa-hedgehog {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f994.svg")
}
.twa-helicopter {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f681.svg")
}
.twa-herb {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f33f.svg")
}
.twa-hibiscus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f33a.svg")
}
.twa-high-heeled-shoe {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f460.svg")
}
.twa-high-speed-train {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f684.svg")
}
.twa-high-voltage {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26a1.svg")
}
.twa-hiking-boot {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f97e.svg")
}
.twa-hindu-temple {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6d5.svg")
}
.twa-hippopotamus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f99b.svg")
}
.twa-hole {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f573.svg")
}
.twa-hollow-red-circle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2b55.svg")
}
.twa-honey-pot {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f36f.svg")
}
.twa-honeybee {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f41d.svg")
}
.twa-hook {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa9d.svg")
}
.twa-horizontal-traffic-light {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a5.svg")
}
.twa-horse {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f40e.svg")
}
.twa-horse-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f434.svg")
}
.twa-horse-racing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c7.svg")
}
.twa-horse-racing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c7-1f3ff.svg")
}
.twa-horse-racing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c7-1f3fb.svg")
}
.twa-horse-racing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c7-1f3fe.svg")
}
.twa-horse-racing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c7-1f3fc.svg")
}
.twa-horse-racing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c7-1f3fd.svg")
}
.twa-hospital {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3e5.svg")
}
.twa-hot-beverage {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2615.svg")
}
.twa-hot-dog {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f32d.svg")
}
.twa-hot-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f975.svg")
}
.twa-hot-pepper {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f336.svg")
}
.twa-hot-springs {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2668.svg")
}
.twa-hotel {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3e8.svg")
}
.twa-hourglass-done {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/231b.svg")
}
.twa-hourglass-not-done {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/23f3.svg")
}
.twa-house {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3e0.svg")
}
.twa-house-with-garden {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3e1.svg")
}
.twa-houses {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3d8.svg")
}
.twa-hugging-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f917.svg")
}
.twa-hundred-points {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4af.svg")
}
.twa-hushed-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f62f.svg")
}
.twa-hut {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6d6.svg")
}
.twa-ice {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ca.svg")
}
.twa-ice-cream {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f368.svg")
}
.twa-ice-hockey {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3d2.svg")
}
.twa-ice-skate {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f8.svg")
}
.twa-id-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f194.svg")
}
.twa-inbox-tray {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4e5.svg")
}
.twa-incoming-envelope {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4e8.svg")
}
.twa-index-pointing-up {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/261d.svg")
}
.twa-index-pointing-up-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/261d-1f3ff.svg")
}
.twa-index-pointing-up-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/261d-1f3fb.svg")
}
.twa-index-pointing-up-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/261d-1f3fe.svg")
}
.twa-index-pointing-up-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/261d-1f3fc.svg")
}
.twa-index-pointing-up-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/261d-1f3fd.svg")
}
.twa-infinity {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/267e.svg")
}
.twa-information {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2139.svg")
}
.twa-input-latin-letters {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f524.svg")
}
.twa-input-latin-lowercase {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f521.svg")
}
.twa-input-latin-uppercase {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f520.svg")
}
.twa-input-numbers {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f522.svg")
}
.twa-input-symbols {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f523.svg")
}
.twa-jack-o-lantern {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f383.svg")
}
.twa-japanese-acceptable-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f251.svg")
}
.twa-japanese-application-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f238.svg")
}
.twa-japanese-bargain-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f250.svg")
}
.twa-japanese-castle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ef.svg")
}
.twa-japanese-congratulations-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/3297.svg")
}
.twa-japanese-discount-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f239.svg")
}
.twa-japanese-dolls {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f38e.svg")
}
.twa-japanese-free-of-charge-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f21a.svg")
}
.twa-japanese-here-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f201.svg")
}
.twa-japanese-monthly-amount-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f237.svg")
}
.twa-japanese-no-vacancy-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f235.svg")
}
.twa-japanese-not-free-of-charge-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f236.svg")
}
.twa-japanese-open-for-business-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f23a.svg")
}
.twa-japanese-passing-grade-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f234.svg")
}
.twa-japanese-post-office {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3e3.svg")
}
.twa-japanese-prohibited-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f232.svg")
}
.twa-japanese-reserved-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f22f.svg")
}
.twa-japanese-secret-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/3299.svg")
}
.twa-japanese-service-charge-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f202.svg")
}
.twa-japanese-symbol-for-beginner {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f530.svg")
}
.twa-japanese-vacancy-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f233.svg")
}
.twa-jeans {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f456.svg")
}
.twa-joker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f0cf.svg")
}
.twa-joystick {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f579.svg")
}
.twa-judge {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-2696-fe0f.svg")
}
.twa-judge-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-2696-fe0f.svg")
}
.twa-judge-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-2696-fe0f.svg")
}
.twa-judge-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-2696-fe0f.svg")
}
.twa-judge-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-2696-fe0f.svg")
}
.twa-judge-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-2696-fe0f.svg")
}
.twa-kaaba {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f54b.svg")
}
.twa-kangaroo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f998.svg")
}
.twa-key {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f511.svg")
}
.twa-keyboard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2328.svg")
}
.twa-keycap {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/23-20e3.svg")
}
.twa-keycap {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2a-20e3.svg")
}
.twa-keycap-0 {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/30-20e3.svg")
}
.twa-keycap-1 {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/31-20e3.svg")
}
.twa-keycap-10 {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f51f.svg")
}
.twa-keycap-2 {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/32-20e3.svg")
}
.twa-keycap-3 {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/33-20e3.svg")
}
.twa-keycap-4 {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/34-20e3.svg")
}
.twa-keycap-5 {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/35-20e3.svg")
}
.twa-keycap-6 {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/36-20e3.svg")
}
.twa-keycap-7 {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/37-20e3.svg")
}
.twa-keycap-8 {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/38-20e3.svg")
}
.twa-keycap-9 {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/39-20e3.svg")
}
.twa-kick-scooter {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6f4.svg")
}
.twa-kimono {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f458.svg")
}
.twa-kiss {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f48f.svg")
}
.twa-kiss-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f48f-1f3ff.svg")
}
.twa-kiss-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f48f-1f3fb.svg")
}
.twa-kiss-man-man {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-2764-fe0f-200d-1f48b-200d-1f468.svg")
}
.twa-kiss-man-man-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff.svg")
}
.twa-kiss-man-man-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb.svg")
}
.twa-kiss-man-man-dark-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe.svg")
}
.twa-kiss-man-man-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc.svg")
}
.twa-kiss-man-man-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd.svg")
}
.twa-kiss-man-man-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb.svg")
}
.twa-kiss-man-man-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff.svg")
}
.twa-kiss-man-man-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe.svg")
}
.twa-kiss-man-man-light-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc.svg")
}
.twa-kiss-man-man-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd.svg")
}
.twa-kiss-man-man-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe.svg")
}
.twa-kiss-man-man-medium-dark-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff.svg")
}
.twa-kiss-man-man-medium-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb.svg")
}
.twa-kiss-man-man-medium-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc.svg")
}
.twa-kiss-man-man-medium-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd.svg")
}
.twa-kiss-man-man-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc.svg")
}
.twa-kiss-man-man-medium-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff.svg")
}
.twa-kiss-man-man-medium-light-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb.svg")
}
.twa-kiss-man-man-medium-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe.svg")
}
.twa-kiss-man-man-medium-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd.svg")
}
.twa-kiss-man-man-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd.svg")
}
.twa-kiss-man-man-medium-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff.svg")
}
.twa-kiss-man-man-medium-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb.svg")
}
.twa-kiss-man-man-medium-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe.svg")
}
.twa-kiss-man-man-medium-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc.svg")
}
.twa-kiss-mark {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f48b.svg")
}
.twa-kiss-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f48f-1f3fe.svg")
}
.twa-kiss-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f48f-1f3fc.svg")
}
.twa-kiss-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f48f-1f3fd.svg")
}
.twa-kiss-person-person-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fb.svg")
}
.twa-kiss-person-person-dark-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fe.svg")
}
.twa-kiss-person-person-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fc.svg")
}
.twa-kiss-person-person-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fd.svg")
}
.twa-kiss-person-person-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3ff.svg")
}
.twa-kiss-person-person-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fe.svg")
}
.twa-kiss-person-person-light-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fc.svg")
}
.twa-kiss-person-person-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fd.svg")
}
.twa-kiss-person-person-medium-dark-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3ff.svg")
}
.twa-kiss-person-person-medium-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fb.svg")
}
.twa-kiss-person-person-medium-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fc.svg")
}
.twa-kiss-person-person-medium-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fd.svg")
}
.twa-kiss-person-person-medium-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3ff.svg")
}
.twa-kiss-person-person-medium-light-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fb.svg")
}
.twa-kiss-person-person-medium-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fe.svg")
}
.twa-kiss-person-person-medium-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fd.svg")
}
.twa-kiss-person-person-medium-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3ff.svg")
}
.twa-kiss-person-person-medium-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fb.svg")
}
.twa-kiss-person-person-medium-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fe.svg")
}
.twa-kiss-person-person-medium-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fc.svg")
}
.twa-kiss-woman-man {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-2764-fe0f-200d-1f48b-200d-1f468.svg")
}
.twa-kiss-woman-man-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff.svg")
}
.twa-kiss-woman-man-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb.svg")
}
.twa-kiss-woman-man-dark-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe.svg")
}
.twa-kiss-woman-man-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc.svg")
}
.twa-kiss-woman-man-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd.svg")
}
.twa-kiss-woman-man-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb.svg")
}
.twa-kiss-woman-man-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff.svg")
}
.twa-kiss-woman-man-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe.svg")
}
.twa-kiss-woman-man-light-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc.svg")
}
.twa-kiss-woman-man-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd.svg")
}
.twa-kiss-woman-man-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe.svg")
}
.twa-kiss-woman-man-medium-dark-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff.svg")
}
.twa-kiss-woman-man-medium-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb.svg")
}
.twa-kiss-woman-man-medium-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc.svg")
}
.twa-kiss-woman-man-medium-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd.svg")
}
.twa-kiss-woman-man-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc.svg")
}
.twa-kiss-woman-man-medium-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff.svg")
}
.twa-kiss-woman-man-medium-light-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb.svg")
}
.twa-kiss-woman-man-medium-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe.svg")
}
.twa-kiss-woman-man-medium-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd.svg")
}
.twa-kiss-woman-man-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd.svg")
}
.twa-kiss-woman-man-medium-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff.svg")
}
.twa-kiss-woman-man-medium-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb.svg")
}
.twa-kiss-woman-man-medium-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe.svg")
}
.twa-kiss-woman-man-medium-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc.svg")
}
.twa-kiss-woman-woman {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-2764-fe0f-200d-1f48b-200d-1f469.svg")
}
.twa-kiss-woman-woman-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff.svg")
}
.twa-kiss-woman-woman-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb.svg")
}
.twa-kiss-woman-woman-dark-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe.svg")
}
.twa-kiss-woman-woman-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc.svg")
}
.twa-kiss-woman-woman-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd.svg")
}
.twa-kiss-woman-woman-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb.svg")
}
.twa-kiss-woman-woman-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff.svg")
}
.twa-kiss-woman-woman-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe.svg")
}
.twa-kiss-woman-woman-light-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc.svg")
}
.twa-kiss-woman-woman-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd.svg")
}
.twa-kiss-woman-woman-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe.svg")
}
.twa-kiss-woman-woman-medium-dark-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff.svg")
}
.twa-kiss-woman-woman-medium-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb.svg")
}
.twa-kiss-woman-woman-medium-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc.svg")
}
.twa-kiss-woman-woman-medium-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd.svg")
}
.twa-kiss-woman-woman-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc.svg")
}
.twa-kiss-woman-woman-medium-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff.svg")
}
.twa-kiss-woman-woman-medium-light-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb.svg")
}
.twa-kiss-woman-woman-medium-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe.svg")
}
.twa-kiss-woman-woman-medium-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd.svg")
}
.twa-kiss-woman-woman-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd.svg")
}
.twa-kiss-woman-woman-medium-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff.svg")
}
.twa-kiss-woman-woman-medium-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb.svg")
}
.twa-kiss-woman-woman-medium-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe.svg")
}
.twa-kiss-woman-woman-medium-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc.svg")
}
.twa-kissing-cat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f63d.svg")
}
.twa-kissing-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f617.svg")
}
.twa-kissing-face-with-closed-eyes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f61a.svg")
}
.twa-kissing-face-with-smiling-eyes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f619.svg")
}
.twa-kitchen-knife {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f52a.svg")
}
.twa-kite {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa81.svg")
}
.twa-kiwi-fruit {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f95d.svg")
}
.twa-knocked-out-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f635.svg")
}
.twa-knot {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1faa2.svg")
}
.twa-koala {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f428.svg")
}
.twa-lab-coat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f97c.svg")
}
.twa-label {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3f7.svg")
}
.twa-lacrosse {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f94d.svg")
}
.twa-ladder {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa9c.svg")
}
.twa-lady-beetle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f41e.svg")
}
.twa-laptop {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4bb.svg")
}
.twa-large-blue-diamond {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f537.svg")
}
.twa-large-orange-diamond {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f536.svg")
}
.twa-last-quarter-moon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f317.svg")
}
.twa-last-quarter-moon-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f31c.svg")
}
.twa-last-track-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/23ee.svg")
}
.twa-latin-cross {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/271d.svg")
}
.twa-leaf-fluttering-in-wind {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f343.svg")
}
.twa-leafy-green {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f96c.svg")
}
.twa-ledger {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4d2.svg")
}
.twa-left-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2b05.svg")
}
.twa-left-arrow-curving-right {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/21aa.svg")
}
.twa-left-facing-fist {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91b.svg")
}
.twa-left-facing-fist-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91b-1f3ff.svg")
}
.twa-left-facing-fist-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91b-1f3fb.svg")
}
.twa-left-facing-fist-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91b-1f3fe.svg")
}
.twa-left-facing-fist-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91b-1f3fc.svg")
}
.twa-left-facing-fist-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91b-1f3fd.svg")
}
.twa-left-luggage {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6c5.svg")
}
.twa-left-right-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2194.svg")
}
.twa-left-speech-bubble {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5e8.svg")
}
.twa-leg {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b5.svg")
}
.twa-leg-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b5-1f3ff.svg")
}
.twa-leg-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b5-1f3fb.svg")
}
.twa-leg-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b5-1f3fe.svg")
}
.twa-leg-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b5-1f3fc.svg")
}
.twa-leg-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b5-1f3fd.svg")
}
.twa-lemon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f34b.svg")
}
.twa-leo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/264c.svg")
}
.twa-leopard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f406.svg")
}
.twa-level-slider {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f39a.svg")
}
.twa-libra {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/264e.svg")
}
.twa-light-bulb {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4a1.svg")
}
.twa-light-rail {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f688.svg")
}
.twa-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3fb.svg")
}
.twa-link {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f517.svg")
}
.twa-linked-paperclips {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f587.svg")
}
.twa-lion {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f981.svg")
}
.twa-lipstick {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f484.svg")
}
.twa-litter-in-bin-sign {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6ae.svg")
}
.twa-lizard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f98e.svg")
}
.twa-llama {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f999.svg")
}
.twa-lobster {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f99e.svg")
}
.twa-locked {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f512.svg")
}
.twa-locked-with-key {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f510.svg")
}
.twa-locked-with-pen {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f50f.svg")
}
.twa-locomotive {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f682.svg")
}
.twa-lollipop {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f36d.svg")
}
.twa-long-drum {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa98.svg")
}
.twa-lotion-bottle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9f4.svg")
}
.twa-loudly-crying-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f62d.svg")
}
.twa-loudspeaker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4e2.svg")
}
.twa-love-hotel {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3e9.svg")
}
.twa-love-letter {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f48c.svg")
}
.twa-love-you-gesture {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91f.svg")
}
.twa-love-you-gesture-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91f-1f3ff.svg")
}
.twa-love-you-gesture-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91f-1f3fb.svg")
}
.twa-love-you-gesture-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91f-1f3fe.svg")
}
.twa-love-you-gesture-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91f-1f3fc.svg")
}
.twa-love-you-gesture-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91f-1f3fd.svg")
}
.twa-luggage {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9f3.svg")
}
.twa-lungs {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fac1.svg")
}
.twa-lying-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f925.svg")
}
.twa-mage {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9.svg")
}
.twa-mage-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-1f3ff.svg")
}
.twa-mage-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-1f3fb.svg")
}
.twa-mage-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-1f3fe.svg")
}
.twa-mage-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-1f3fc.svg")
}
.twa-mage-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-1f3fd.svg")
}
.twa-magic-wand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa84.svg")
}
.twa-magnet {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9f2.svg")
}
.twa-magnifying-glass-tilted-left {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f50d.svg")
}
.twa-magnifying-glass-tilted-right {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f50e.svg")
}
.twa-mahjong-red-dragon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f004.svg")
}
.twa-male-sign {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2642.svg")
}
.twa-mammoth {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9a3.svg")
}
.twa-man {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468.svg")
}
.twa-man-artist {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f3a8.svg")
}
.twa-man-artist-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f3a8.svg")
}
.twa-man-artist-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f3a8.svg")
}
.twa-man-artist-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f3a8.svg")
}
.twa-man-artist-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f3a8.svg")
}
.twa-man-artist-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f3a8.svg")
}
.twa-man-astronaut {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f680.svg")
}
.twa-man-astronaut-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f680.svg")
}
.twa-man-astronaut-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f680.svg")
}
.twa-man-astronaut-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f680.svg")
}
.twa-man-astronaut-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f680.svg")
}
.twa-man-astronaut-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f680.svg")
}
.twa-man-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f9b2.svg")
}
.twa-man-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-200d-2642-fe0f.svg")
}
.twa-man-biking {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-200d-2642-fe0f.svg")
}
.twa-man-biking-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-biking-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-biking-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-biking-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-biking-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-200d-2642-fe0f.svg")
}
.twa-man-bouncing-ball {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-fe0f-200d-2642-fe0f.svg")
}
.twa-man-bouncing-ball-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-bouncing-ball-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-bouncing-ball-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-bouncing-ball-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-bouncing-ball-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-bowing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-200d-2642-fe0f.svg")
}
.twa-man-bowing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-bowing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-bowing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-bowing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-bowing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-cartwheeling {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-200d-2642-fe0f.svg")
}
.twa-man-cartwheeling-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-cartwheeling-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-cartwheeling-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-cartwheeling-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-cartwheeling-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-christmas {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f384.svg")
}
.twa-man-climbing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-200d-2642-fe0f.svg")
}
.twa-man-climbing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-climbing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-climbing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-climbing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-climbing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-construction-worker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-200d-2642-fe0f.svg")
}
.twa-man-construction-worker-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-construction-worker-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-construction-worker-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-construction-worker-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-construction-worker-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-cook {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f373.svg")
}
.twa-man-cook-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f373.svg")
}
.twa-man-cook-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f373.svg")
}
.twa-man-cook-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f373.svg")
}
.twa-man-cook-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f373.svg")
}
.twa-man-cook-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f373.svg")
}
.twa-man-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f9b1.svg")
}
.twa-man-dancing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f57a.svg")
}
.twa-man-dancing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f57a-1f3ff.svg")
}
.twa-man-dancing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f57a-1f3fb.svg")
}
.twa-man-dancing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f57a-1f3fe.svg")
}
.twa-man-dancing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f57a-1f3fc.svg")
}
.twa-man-dancing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f57a-1f3fd.svg")
}
.twa-man-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff.svg")
}
.twa-man-dark-skin-tone-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f9b2.svg")
}
.twa-man-dark-skin-tone-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-dark-skin-tone-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-dark-skin-tone-christmas {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f384.svg")
}
.twa-man-dark-skin-tone-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f9b1.svg")
}
.twa-man-dark-skin-tone-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f9b0.svg")
}
.twa-man-dark-skin-tone-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f9b3.svg")
}
.twa-man-detective {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-fe0f-200d-2642-fe0f.svg")
}
.twa-man-detective-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-detective-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-detective-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-detective-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-detective-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-elf {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-200d-2642-fe0f.svg")
}
.twa-man-elf-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-elf-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-elf-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-elf-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-elf-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-facepalming {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-200d-2642-fe0f.svg")
}
.twa-man-facepalming-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-facepalming-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-facepalming-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-facepalming-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-facepalming-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-factory-worker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f3ed.svg")
}
.twa-man-factory-worker-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f3ed.svg")
}
.twa-man-factory-worker-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f3ed.svg")
}
.twa-man-factory-worker-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f3ed.svg")
}
.twa-man-factory-worker-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f3ed.svg")
}
.twa-man-factory-worker-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f3ed.svg")
}
.twa-man-fairy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-200d-2642-fe0f.svg")
}
.twa-man-fairy-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-fairy-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-fairy-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-fairy-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-fairy-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-farmer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f33e.svg")
}
.twa-man-farmer-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f33e.svg")
}
.twa-man-farmer-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f33e.svg")
}
.twa-man-farmer-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f33e.svg")
}
.twa-man-farmer-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f33e.svg")
}
.twa-man-farmer-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f33e.svg")
}
.twa-man-feeding-baby {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f37c.svg")
}
.twa-man-feeding-baby-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f37c.svg")
}
.twa-man-feeding-baby-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f37c.svg")
}
.twa-man-feeding-baby-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f37c.svg")
}
.twa-man-feeding-baby-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f37c.svg")
}
.twa-man-feeding-baby-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f37c.svg")
}
.twa-man-firefighter {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f692.svg")
}
.twa-man-firefighter-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f692.svg")
}
.twa-man-firefighter-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f692.svg")
}
.twa-man-firefighter-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f692.svg")
}
.twa-man-firefighter-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f692.svg")
}
.twa-man-firefighter-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f692.svg")
}
.twa-man-frowning {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-200d-2642-fe0f.svg")
}
.twa-man-frowning-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-frowning-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-frowning-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-frowning-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-frowning-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-genie {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9de-200d-2642-fe0f.svg")
}
.twa-man-gesturing-no {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-200d-2642-fe0f.svg")
}
.twa-man-gesturing-no-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-gesturing-no-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-gesturing-no-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-gesturing-no-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-gesturing-no-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-gesturing-ok {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-200d-2642-fe0f.svg")
}
.twa-man-gesturing-ok-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-gesturing-ok-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-gesturing-ok-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-gesturing-ok-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-gesturing-ok-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-getting-haircut {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-200d-2642-fe0f.svg")
}
.twa-man-getting-haircut-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-getting-haircut-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-getting-haircut-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-getting-haircut-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-getting-haircut-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-getting-massage {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-200d-2642-fe0f.svg")
}
.twa-man-getting-massage-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-getting-massage-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-getting-massage-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-getting-massage-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-getting-massage-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-golfing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-fe0f-200d-2642-fe0f.svg")
}
.twa-man-golfing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-golfing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-golfing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-golfing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-golfing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-guard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-200d-2642-fe0f.svg")
}
.twa-man-guard-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-guard-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-guard-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-guard-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-guard-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-health-worker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-2695-fe0f.svg")
}
.twa-man-health-worker-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-2695-fe0f.svg")
}
.twa-man-health-worker-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-2695-fe0f.svg")
}
.twa-man-health-worker-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-2695-fe0f.svg")
}
.twa-man-health-worker-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-2695-fe0f.svg")
}
.twa-man-health-worker-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-2695-fe0f.svg")
}
.twa-man-in-business-suit-levitating-dark-skin-tone-female {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-1f3ff-200d-2640-fe0f.svg")
}
.twa-man-in-business-suit-levitating-dark-skin-tone-male {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-in-business-suit-levitating-female {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-fe0f-200d-2640-fe0f.svg")
}
.twa-man-in-business-suit-levitating-light-skin-tone-female {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-1f3fb-200d-2640-fe0f.svg")
}
.twa-man-in-business-suit-levitating-light-skin-tone-male {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-in-business-suit-levitating-male {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-fe0f-200d-2642-fe0f.svg")
}
.twa-man-in-business-suit-levitating-medium-dark-skin-tone-female {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-1f3fe-200d-2640-fe0f.svg")
}
.twa-man-in-business-suit-levitating-medium-dark-skin-tone-male {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-in-business-suit-levitating-medium-light-skin-tone-female {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-1f3fc-200d-2640-fe0f.svg")
}
.twa-man-in-business-suit-levitating-medium-light-skin-tone-male {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-in-business-suit-levitating-medium-skin-tone-female {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-1f3fd-200d-2640-fe0f.svg")
}
.twa-man-in-business-suit-levitating-medium-skin-tone-male {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-in-lotus-position {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-200d-2642-fe0f.svg")
}
.twa-man-in-lotus-position-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-in-lotus-position-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-in-lotus-position-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-in-lotus-position-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-in-lotus-position-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-in-manual-wheelchair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f9bd.svg")
}
.twa-man-in-manual-wheelchair-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f9bd.svg")
}
.twa-man-in-manual-wheelchair-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f9bd.svg")
}
.twa-man-in-manual-wheelchair-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f9bd.svg")
}
.twa-man-in-manual-wheelchair-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f9bd.svg")
}
.twa-man-in-manual-wheelchair-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f9bd.svg")
}
.twa-man-in-motorized-wheelchair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f9bc.svg")
}
.twa-man-in-motorized-wheelchair-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f9bc.svg")
}
.twa-man-in-motorized-wheelchair-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f9bc.svg")
}
.twa-man-in-motorized-wheelchair-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f9bc.svg")
}
.twa-man-in-motorized-wheelchair-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f9bc.svg")
}
.twa-man-in-motorized-wheelchair-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f9bc.svg")
}
.twa-man-in-steamy-room {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-200d-2642-fe0f.svg")
}
.twa-man-in-steamy-room-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-in-steamy-room-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-in-steamy-room-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-in-steamy-room-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-in-steamy-room-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-in-tuxedo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-200d-2642-fe0f.svg")
}
.twa-man-in-tuxedo-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-in-tuxedo-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-in-tuxedo-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-in-tuxedo-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-in-tuxedo-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-judge {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-2696-fe0f.svg")
}
.twa-man-judge-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-2696-fe0f.svg")
}
.twa-man-judge-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-2696-fe0f.svg")
}
.twa-man-judge-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-2696-fe0f.svg")
}
.twa-man-judge-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-2696-fe0f.svg")
}
.twa-man-judge-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-2696-fe0f.svg")
}
.twa-man-juggling {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-200d-2642-fe0f.svg")
}
.twa-man-juggling-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-juggling-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-juggling-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-juggling-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-juggling-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-kneeling {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-200d-2642-fe0f.svg")
}
.twa-man-kneeling-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-kneeling-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-kneeling-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-kneeling-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-kneeling-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-lifting-weights {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-fe0f-200d-2642-fe0f.svg")
}
.twa-man-lifting-weights-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-lifting-weights-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-lifting-weights-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-lifting-weights-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-lifting-weights-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb.svg")
}
.twa-man-light-skin-tone-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f9b2.svg")
}
.twa-man-light-skin-tone-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-light-skin-tone-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-light-skin-tone-christmas {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f384.svg")
}
.twa-man-light-skin-tone-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f9b1.svg")
}
.twa-man-light-skin-tone-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f9b0.svg")
}
.twa-man-light-skin-tone-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f9b3.svg")
}
.twa-man-mage {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-200d-2642-fe0f.svg")
}
.twa-man-mage-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-mage-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-mage-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-mage-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-mage-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-mechanic {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f527.svg")
}
.twa-man-mechanic-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f527.svg")
}
.twa-man-mechanic-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f527.svg")
}
.twa-man-mechanic-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f527.svg")
}
.twa-man-mechanic-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f527.svg")
}
.twa-man-mechanic-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f527.svg")
}
.twa-man-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe.svg")
}
.twa-man-medium-dark-skin-tone-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f9b2.svg")
}
.twa-man-medium-dark-skin-tone-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-medium-dark-skin-tone-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-medium-dark-skin-tone-christmas {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f384.svg")
}
.twa-man-medium-dark-skin-tone-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f9b1.svg")
}
.twa-man-medium-dark-skin-tone-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f9b0.svg")
}
.twa-man-medium-dark-skin-tone-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f9b3.svg")
}
.twa-man-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc.svg")
}
.twa-man-medium-light-skin-tone-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f9b2.svg")
}
.twa-man-medium-light-skin-tone-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-medium-light-skin-tone-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-medium-light-skin-tone-christmas {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f384.svg")
}
.twa-man-medium-light-skin-tone-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f9b1.svg")
}
.twa-man-medium-light-skin-tone-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f9b0.svg")
}
.twa-man-medium-light-skin-tone-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f9b3.svg")
}
.twa-man-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd.svg")
}
.twa-man-medium-skin-tone-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f9b2.svg")
}
.twa-man-medium-skin-tone-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-medium-skin-tone-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-medium-skin-tone-christmas {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f384.svg")
}
.twa-man-medium-skin-tone-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f9b1.svg")
}
.twa-man-medium-skin-tone-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f9b0.svg")
}
.twa-man-medium-skin-tone-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f9b3.svg")
}
.twa-man-mountain-biking {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-200d-2642-fe0f.svg")
}
.twa-man-mountain-biking-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-mountain-biking-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-mountain-biking-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-mountain-biking-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-mountain-biking-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-office-worker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f4bc.svg")
}
.twa-man-office-worker-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f4bc.svg")
}
.twa-man-office-worker-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f4bc.svg")
}
.twa-man-office-worker-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f4bc.svg")
}
.twa-man-office-worker-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f4bc.svg")
}
.twa-man-office-worker-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f4bc.svg")
}
.twa-man-pilot {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-2708-fe0f.svg")
}
.twa-man-pilot-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-2708-fe0f.svg")
}
.twa-man-pilot-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-2708-fe0f.svg")
}
.twa-man-pilot-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-2708-fe0f.svg")
}
.twa-man-pilot-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-2708-fe0f.svg")
}
.twa-man-pilot-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-2708-fe0f.svg")
}
.twa-man-playing-handball {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-200d-2642-fe0f.svg")
}
.twa-man-playing-handball-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-playing-handball-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-playing-handball-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-playing-handball-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-playing-handball-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-playing-water-polo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-200d-2642-fe0f.svg")
}
.twa-man-playing-water-polo-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-playing-water-polo-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-playing-water-polo-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-playing-water-polo-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-playing-water-polo-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-police-officer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-200d-2642-fe0f.svg")
}
.twa-man-police-officer-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-police-officer-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-police-officer-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-police-officer-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-police-officer-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-pouting {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-200d-2642-fe0f.svg")
}
.twa-man-pouting-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-pouting-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-pouting-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-pouting-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-pouting-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-raising-hand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-200d-2642-fe0f.svg")
}
.twa-man-raising-hand-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-raising-hand-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-raising-hand-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-raising-hand-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-raising-hand-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f9b0.svg")
}
.twa-man-rowing-boat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-200d-2642-fe0f.svg")
}
.twa-man-rowing-boat-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-rowing-boat-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-rowing-boat-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-rowing-boat-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-rowing-boat-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-running {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-200d-2642-fe0f.svg")
}
.twa-man-running-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-running-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-running-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-running-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-running-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-s-shoe {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f45e.svg")
}
.twa-man-scientist {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f52c.svg")
}
.twa-man-scientist-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f52c.svg")
}
.twa-man-scientist-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f52c.svg")
}
.twa-man-scientist-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f52c.svg")
}
.twa-man-scientist-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f52c.svg")
}
.twa-man-scientist-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f52c.svg")
}
.twa-man-shrugging {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-200d-2642-fe0f.svg")
}
.twa-man-shrugging-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-shrugging-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-shrugging-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-shrugging-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-shrugging-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-singer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f3a4.svg")
}
.twa-man-singer-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f3a4.svg")
}
.twa-man-singer-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f3a4.svg")
}
.twa-man-singer-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f3a4.svg")
}
.twa-man-singer-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f3a4.svg")
}
.twa-man-singer-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f3a4.svg")
}
.twa-man-standing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-200d-2642-fe0f.svg")
}
.twa-man-standing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-standing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-standing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-standing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-standing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-student {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f393.svg")
}
.twa-man-student-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f393.svg")
}
.twa-man-student-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f393.svg")
}
.twa-man-student-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f393.svg")
}
.twa-man-student-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f393.svg")
}
.twa-man-student-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f393.svg")
}
.twa-man-superhero {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-200d-2642-fe0f.svg")
}
.twa-man-superhero-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-superhero-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-superhero-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-superhero-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-superhero-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-supervillain {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-200d-2642-fe0f.svg")
}
.twa-man-supervillain-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-supervillain-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-supervillain-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-supervillain-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-supervillain-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-surfing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-200d-2642-fe0f.svg")
}
.twa-man-surfing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-surfing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-surfing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-surfing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-surfing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-swimming {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-200d-2642-fe0f.svg")
}
.twa-man-swimming-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-swimming-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-swimming-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-swimming-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-swimming-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-teacher {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f3eb.svg")
}
.twa-man-teacher-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f3eb.svg")
}
.twa-man-teacher-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f3eb.svg")
}
.twa-man-teacher-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f3eb.svg")
}
.twa-man-teacher-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f3eb.svg")
}
.twa-man-teacher-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f3eb.svg")
}
.twa-man-technologist {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f4bb.svg")
}
.twa-man-technologist-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f4bb.svg")
}
.twa-man-technologist-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f4bb.svg")
}
.twa-man-technologist-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f4bb.svg")
}
.twa-man-technologist-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f4bb.svg")
}
.twa-man-technologist-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f4bb.svg")
}
.twa-man-tipping-hand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-200d-2642-fe0f.svg")
}
.twa-man-tipping-hand-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-tipping-hand-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-tipping-hand-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-tipping-hand-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-tipping-hand-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-vampire {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-200d-2642-fe0f.svg")
}
.twa-man-vampire-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-vampire-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-vampire-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-vampire-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-vampire-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-walking {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-200d-2642-fe0f.svg")
}
.twa-man-walking-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-walking-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-walking-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-walking-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-walking-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-wearing-turban {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-200d-2642-fe0f.svg")
}
.twa-man-wearing-turban-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-wearing-turban-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-wearing-turban-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-wearing-turban-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-wearing-turban-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f9b3.svg")
}
.twa-man-with-veil {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-200d-2642-fe0f.svg")
}
.twa-man-with-veil-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-1f3ff-200d-2642-fe0f.svg")
}
.twa-man-with-veil-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-1f3fb-200d-2642-fe0f.svg")
}
.twa-man-with-veil-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-1f3fe-200d-2642-fe0f.svg")
}
.twa-man-with-veil-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-1f3fc-200d-2642-fe0f.svg")
}
.twa-man-with-veil-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-1f3fd-200d-2642-fe0f.svg")
}
.twa-man-with-white-cane {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-200d-1f9af.svg")
}
.twa-man-with-white-cane-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f9af.svg")
}
.twa-man-with-white-cane-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f9af.svg")
}
.twa-man-with-white-cane-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f9af.svg")
}
.twa-man-with-white-cane-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f9af.svg")
}
.twa-man-with-white-cane-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f9af.svg")
}
.twa-man-zombie {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9df-200d-2642-fe0f.svg")
}
.twa-mango {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f96d.svg")
}
.twa-mantelpiece-clock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f570.svg")
}
.twa-manual-wheelchair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9bd.svg")
}
.twa-map-of-japan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5fe.svg")
}
.twa-maple-leaf {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f341.svg")
}
.twa-martial-arts-uniform {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f94b.svg")
}
.twa-mate {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9c9.svg")
}
.twa-meat-on-bone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f356.svg")
}
.twa-mechanic {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f527.svg")
}
.twa-mechanic-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f527.svg")
}
.twa-mechanic-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f527.svg")
}
.twa-mechanic-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f527.svg")
}
.twa-mechanic-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f527.svg")
}
.twa-mechanic-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f527.svg")
}
.twa-mechanical-arm {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9be.svg")
}
.twa-mechanical-leg {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9bf.svg")
}
.twa-medical-symbol {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2695.svg")
}
.twa-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3fe.svg")
}
.twa-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3fc.svg")
}
.twa-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3fd.svg")
}
.twa-megaphone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4e3.svg")
}
.twa-melon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f348.svg")
}
.twa-memo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4dd.svg")
}
.twa-men-holding-hands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46c.svg")
}
.twa-men-holding-hands-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46c-1f3ff.svg")
}
.twa-men-holding-hands-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f91d-200d-1f468-1f3fb.svg")
}
.twa-men-holding-hands-dark-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f91d-200d-1f468-1f3fe.svg")
}
.twa-men-holding-hands-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f91d-200d-1f468-1f3fc.svg")
}
.twa-men-holding-hands-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3ff-200d-1f91d-200d-1f468-1f3fd.svg")
}
.twa-men-holding-hands-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46c-1f3fb.svg")
}
.twa-men-holding-hands-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f91d-200d-1f468-1f3ff.svg")
}
.twa-men-holding-hands-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f91d-200d-1f468-1f3fe.svg")
}
.twa-men-holding-hands-light-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f91d-200d-1f468-1f3fc.svg")
}
.twa-men-holding-hands-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fb-200d-1f91d-200d-1f468-1f3fd.svg")
}
.twa-men-holding-hands-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46c-1f3fe.svg")
}
.twa-men-holding-hands-medium-dark-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f91d-200d-1f468-1f3ff.svg")
}
.twa-men-holding-hands-medium-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f91d-200d-1f468-1f3fb.svg")
}
.twa-men-holding-hands-medium-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f91d-200d-1f468-1f3fc.svg")
}
.twa-men-holding-hands-medium-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fe-200d-1f91d-200d-1f468-1f3fd.svg")
}
.twa-men-holding-hands-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46c-1f3fc.svg")
}
.twa-men-holding-hands-medium-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f91d-200d-1f468-1f3ff.svg")
}
.twa-men-holding-hands-medium-light-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f91d-200d-1f468-1f3fb.svg")
}
.twa-men-holding-hands-medium-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f91d-200d-1f468-1f3fe.svg")
}
.twa-men-holding-hands-medium-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fc-200d-1f91d-200d-1f468-1f3fd.svg")
}
.twa-men-holding-hands-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46c-1f3fd.svg")
}
.twa-men-holding-hands-medium-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f91d-200d-1f468-1f3ff.svg")
}
.twa-men-holding-hands-medium-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f91d-200d-1f468-1f3fb.svg")
}
.twa-men-holding-hands-medium-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f91d-200d-1f468-1f3fe.svg")
}
.twa-men-holding-hands-medium-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f468-1f3fd-200d-1f91d-200d-1f468-1f3fc.svg")
}
.twa-men-s-room {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b9.svg")
}
.twa-men-with-bunny-ears {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46f-200d-2642-fe0f.svg")
}
.twa-men-wrestling {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93c-200d-2642-fe0f.svg")
}
.twa-mending-heart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2764-fe0f-200d-1fa79.svg")
}
.twa-menorah {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f54e.svg")
}
.twa-mermaid {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-200d-2640-fe0f.svg")
}
.twa-mermaid-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-1f3ff-200d-2640-fe0f.svg")
}
.twa-mermaid-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-1f3fb-200d-2640-fe0f.svg")
}
.twa-mermaid-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-1f3fe-200d-2640-fe0f.svg")
}
.twa-mermaid-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-1f3fc-200d-2640-fe0f.svg")
}
.twa-mermaid-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-1f3fd-200d-2640-fe0f.svg")
}
.twa-merman {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-200d-2642-fe0f.svg")
}
.twa-merman-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-1f3ff-200d-2642-fe0f.svg")
}
.twa-merman-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-1f3fb-200d-2642-fe0f.svg")
}
.twa-merman-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-1f3fe-200d-2642-fe0f.svg")
}
.twa-merman-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-1f3fc-200d-2642-fe0f.svg")
}
.twa-merman-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-1f3fd-200d-2642-fe0f.svg")
}
.twa-merperson {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc.svg")
}
.twa-merperson-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-1f3ff.svg")
}
.twa-merperson-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-1f3fb.svg")
}
.twa-merperson-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-1f3fe.svg")
}
.twa-merperson-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-1f3fc.svg")
}
.twa-merperson-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dc-1f3fd.svg")
}
.twa-metro {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f687.svg")
}
.twa-microbe {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9a0.svg")
}
.twa-microphone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3a4.svg")
}
.twa-microscope {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f52c.svg")
}
.twa-middle-finger {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f595.svg")
}
.twa-middle-finger-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f595-1f3ff.svg")
}
.twa-middle-finger-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f595-1f3fb.svg")
}
.twa-middle-finger-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f595-1f3fe.svg")
}
.twa-middle-finger-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f595-1f3fc.svg")
}
.twa-middle-finger-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f595-1f3fd.svg")
}
.twa-military-helmet {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa96.svg")
}
.twa-military-medal {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f396.svg")
}
.twa-milky-way {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f30c.svg")
}
.twa-minibus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f690.svg")
}
.twa-minus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2796.svg")
}
.twa-mirror {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa9e.svg")
}
.twa-moai {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5ff.svg")
}
.twa-mobile-phone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4f1.svg")
}
.twa-mobile-phone-off {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4f4.svg")
}
.twa-mobile-phone-with-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4f2.svg")
}
.twa-money-bag {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4b0.svg")
}
.twa-money-mouth-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f911.svg")
}
.twa-money-with-wings {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4b8.svg")
}
.twa-monkey {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f412.svg")
}
.twa-monkey-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f435.svg")
}
.twa-monorail {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f69d.svg")
}
.twa-moon-cake {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f96e.svg")
}
.twa-moon-viewing-ceremony {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f391.svg")
}
.twa-mosque {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f54c.svg")
}
.twa-mosquito {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f99f.svg")
}
.twa-motor-boat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6e5.svg")
}
.twa-motor-scooter {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6f5.svg")
}
.twa-motorcycle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cd.svg")
}
.twa-motorized-wheelchair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9bc.svg")
}
.twa-motorway {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6e3.svg")
}
.twa-mount-fuji {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5fb.svg")
}
.twa-mountain {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f0.svg")
}
.twa-mountain-cableway {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a0.svg")
}
.twa-mountain-railway {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f69e.svg")
}
.twa-mouse {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f401.svg")
}
.twa-mouse-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f42d.svg")
}
.twa-mouse-trap {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1faa4.svg")
}
.twa-mouth {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f444.svg")
}
.twa-movie-camera {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3a5.svg")
}
.twa-mrs-claus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f936.svg")
}
.twa-mrs-claus-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f936-1f3ff.svg")
}
.twa-mrs-claus-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f936-1f3fb.svg")
}
.twa-mrs-claus-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f936-1f3fe.svg")
}
.twa-mrs-claus-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f936-1f3fc.svg")
}
.twa-mrs-claus-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f936-1f3fd.svg")
}
.twa-multiply {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2716.svg")
}
.twa-mushroom {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f344.svg")
}
.twa-musical-keyboard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3b9.svg")
}
.twa-musical-note {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3b5.svg")
}
.twa-musical-notes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3b6.svg")
}
.twa-musical-score {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3bc.svg")
}
.twa-muted-speaker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f507.svg")
}
.twa-mx-claus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f384.svg")
}
.twa-mx-claus-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f384.svg")
}
.twa-mx-claus-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f384.svg")
}
.twa-mx-claus-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f384.svg")
}
.twa-mx-claus-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f384.svg")
}
.twa-mx-claus-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f384.svg")
}
.twa-nail-polish {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f485.svg")
}
.twa-nail-polish-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f485-1f3ff.svg")
}
.twa-nail-polish-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f485-1f3fb.svg")
}
.twa-nail-polish-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f485-1f3fe.svg")
}
.twa-nail-polish-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f485-1f3fc.svg")
}
.twa-nail-polish-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f485-1f3fd.svg")
}
.twa-name-badge {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4db.svg")
}
.twa-national-park {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3de.svg")
}
.twa-nauseated-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f922.svg")
}
.twa-nazar-amulet {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ff.svg")
}
.twa-necktie {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f454.svg")
}
.twa-nerd-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f913.svg")
}
.twa-nesting-dolls {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa86.svg")
}
.twa-neutral-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f610.svg")
}
.twa-new-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f195.svg")
}
.twa-new-moon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f311.svg")
}
.twa-new-moon-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f31a.svg")
}
.twa-newspaper {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4f0.svg")
}
.twa-next-track-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/23ed.svg")
}
.twa-ng-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f196.svg")
}
.twa-night-with-stars {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f303.svg")
}
.twa-nine-o-clock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f558.svg")
}
.twa-nine-thirty {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f564.svg")
}
.twa-ninja {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f977.svg")
}
.twa-ninja-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f977-1f3ff.svg")
}
.twa-ninja-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f977-1f3fb.svg")
}
.twa-ninja-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f977-1f3fe.svg")
}
.twa-ninja-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f977-1f3fc.svg")
}
.twa-ninja-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f977-1f3fd.svg")
}
.twa-no-bicycles {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b3.svg")
}
.twa-no-entry {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26d4.svg")
}
.twa-no-littering {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6af.svg")
}
.twa-no-mobile-phones {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4f5.svg")
}
.twa-no-one-under-eighteen {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f51e.svg")
}
.twa-no-pedestrians {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b7.svg")
}
.twa-no-smoking {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6ad.svg")
}
.twa-non-potable-water {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b1.svg")
}
.twa-nose {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f443.svg")
}
.twa-nose-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f443-1f3ff.svg")
}
.twa-nose-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f443-1f3fb.svg")
}
.twa-nose-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f443-1f3fe.svg")
}
.twa-nose-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f443-1f3fc.svg")
}
.twa-nose-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f443-1f3fd.svg")
}
.twa-notebook {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4d3.svg")
}
.twa-notebook-with-decorative-cover {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4d4.svg")
}
.twa-nut-and-bolt {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f529.svg")
}
.twa-o-button-blood-type {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f17e.svg")
}
.twa-octopus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f419.svg")
}
.twa-oden {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f362.svg")
}
.twa-office-building {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3e2.svg")
}
.twa-office-worker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f4bc.svg")
}
.twa-office-worker-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f4bc.svg")
}
.twa-office-worker-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f4bc.svg")
}
.twa-office-worker-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f4bc.svg")
}
.twa-office-worker-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f4bc.svg")
}
.twa-office-worker-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f4bc.svg")
}
.twa-ogre {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f479.svg")
}
.twa-oil-drum {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6e2.svg")
}
.twa-ok-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f197.svg")
}
.twa-ok-hand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44c.svg")
}
.twa-ok-hand-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44c-1f3ff.svg")
}
.twa-ok-hand-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44c-1f3fb.svg")
}
.twa-ok-hand-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44c-1f3fe.svg")
}
.twa-ok-hand-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44c-1f3fc.svg")
}
.twa-ok-hand-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44c-1f3fd.svg")
}
.twa-old-key {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5dd.svg")
}
.twa-old-man {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f474.svg")
}
.twa-old-man-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f474-1f3ff.svg")
}
.twa-old-man-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f474-1f3fb.svg")
}
.twa-old-man-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f474-1f3fe.svg")
}
.twa-old-man-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f474-1f3fc.svg")
}
.twa-old-man-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f474-1f3fd.svg")
}
.twa-old-woman {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f475.svg")
}
.twa-old-woman-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f475-1f3ff.svg")
}
.twa-old-woman-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f475-1f3fb.svg")
}
.twa-old-woman-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f475-1f3fe.svg")
}
.twa-old-woman-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f475-1f3fc.svg")
}
.twa-old-woman-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f475-1f3fd.svg")
}
.twa-older-person {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d3.svg")
}
.twa-older-person-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d3-1f3ff.svg")
}
.twa-older-person-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d3-1f3fb.svg")
}
.twa-older-person-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d3-1f3fe.svg")
}
.twa-older-person-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d3-1f3fc.svg")
}
.twa-older-person-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d3-1f3fd.svg")
}
.twa-olive {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fad2.svg")
}
.twa-om {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f549.svg")
}
.twa-on-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f51b.svg")
}
.twa-oncoming-automobile {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f698.svg")
}
.twa-oncoming-bus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f68d.svg")
}
.twa-oncoming-fist {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44a.svg")
}
.twa-oncoming-fist-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44a-1f3ff.svg")
}
.twa-oncoming-fist-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44a-1f3fb.svg")
}
.twa-oncoming-fist-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44a-1f3fe.svg")
}
.twa-oncoming-fist-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44a-1f3fc.svg")
}
.twa-oncoming-fist-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44a-1f3fd.svg")
}
.twa-oncoming-police-car {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f694.svg")
}
.twa-oncoming-taxi {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f696.svg")
}
.twa-one-o-clock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f550.svg")
}
.twa-one-piece-swimsuit {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa71.svg")
}
.twa-one-thirty {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f55c.svg")
}
.twa-onion {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9c5.svg")
}
.twa-open-book {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4d6.svg")
}
.twa-open-file-folder {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4c2.svg")
}
.twa-open-hands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f450.svg")
}
.twa-open-hands-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f450-1f3ff.svg")
}
.twa-open-hands-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f450-1f3fb.svg")
}
.twa-open-hands-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f450-1f3fe.svg")
}
.twa-open-hands-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f450-1f3fc.svg")
}
.twa-open-hands-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f450-1f3fd.svg")
}
.twa-open-mailbox-with-lowered-flag {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4ed.svg")
}
.twa-open-mailbox-with-raised-flag {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4ec.svg")
}
.twa-ophiuchus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26ce.svg")
}
.twa-optical-disk {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4bf.svg")
}
.twa-orange-book {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4d9.svg")
}
.twa-orange-circle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f7e0.svg")
}
.twa-orange-heart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9e1.svg")
}
.twa-orange-square {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f7e7.svg")
}
.twa-orangutan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9a7.svg")
}
.twa-orthodox-cross {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2626.svg")
}
.twa-otter {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9a6.svg")
}
.twa-outbox-tray {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4e4.svg")
}
.twa-owl {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f989.svg")
}
.twa-ox {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f402.svg")
}
.twa-oyster {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9aa.svg")
}
.twa-p-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f17f.svg")
}
.twa-package {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4e6.svg")
}
.twa-page-facing-up {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4c4.svg")
}
.twa-page-with-curl {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4c3.svg")
}
.twa-pager {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4df.svg")
}
.twa-paintbrush {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f58c.svg")
}
.twa-palm-tree {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f334.svg")
}
.twa-palms-up-together {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f932.svg")
}
.twa-palms-up-together-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f932-1f3ff.svg")
}
.twa-palms-up-together-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f932-1f3fb.svg")
}
.twa-palms-up-together-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f932-1f3fe.svg")
}
.twa-palms-up-together-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f932-1f3fc.svg")
}
.twa-palms-up-together-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f932-1f3fd.svg")
}
.twa-pancakes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f95e.svg")
}
.twa-panda {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f43c.svg")
}
.twa-paperclip {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4ce.svg")
}
.twa-parachute {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa82.svg")
}
.twa-parrot {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f99c.svg")
}
.twa-part-alternation-mark {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/303d.svg")
}
.twa-party-popper {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f389.svg")
}
.twa-partying-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f973.svg")
}
.twa-passenger-ship {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6f3.svg")
}
.twa-passport-control {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6c2.svg")
}
.twa-pause-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/23f8.svg")
}
.twa-paw-prints {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f43e.svg")
}
.twa-peace-symbol {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/262e.svg")
}
.twa-peach {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f351.svg")
}
.twa-peacock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f99a.svg")
}
.twa-peanuts {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f95c.svg")
}
.twa-pear {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f350.svg")
}
.twa-pen {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f58a.svg")
}
.twa-pencil {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270f.svg")
}
.twa-penguin {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f427.svg")
}
.twa-pensive-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f614.svg")
}
.twa-people-holding-hands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f91d-200d-1f9d1.svg")
}
.twa-people-holding-hands-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3ff.svg")
}
.twa-people-holding-hands-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fb.svg")
}
.twa-people-holding-hands-dark-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fe.svg")
}
.twa-people-holding-hands-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fc.svg")
}
.twa-people-holding-hands-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fd.svg")
}
.twa-people-holding-hands-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fb.svg")
}
.twa-people-holding-hands-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3ff.svg")
}
.twa-people-holding-hands-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fe.svg")
}
.twa-people-holding-hands-light-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fc.svg")
}
.twa-people-holding-hands-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fd.svg")
}
.twa-people-holding-hands-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fe.svg")
}
.twa-people-holding-hands-medium-dark-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3ff.svg")
}
.twa-people-holding-hands-medium-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fb.svg")
}
.twa-people-holding-hands-medium-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fc.svg")
}
.twa-people-holding-hands-medium-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fd.svg")
}
.twa-people-holding-hands-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fc.svg")
}
.twa-people-holding-hands-medium-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3ff.svg")
}
.twa-people-holding-hands-medium-light-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fb.svg")
}
.twa-people-holding-hands-medium-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fe.svg")
}
.twa-people-holding-hands-medium-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fd.svg")
}
.twa-people-holding-hands-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fd.svg")
}
.twa-people-holding-hands-medium-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3ff.svg")
}
.twa-people-holding-hands-medium-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fb.svg")
}
.twa-people-holding-hands-medium-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fe.svg")
}
.twa-people-holding-hands-medium-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fc.svg")
}
.twa-people-hugging {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fac2.svg")
}
.twa-people-with-bunny-ears {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46f.svg")
}
.twa-people-wrestling {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93c.svg")
}
.twa-performing-arts {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ad.svg")
}
.twa-persevering-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f623.svg")
}
.twa-person {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1.svg")
}
.twa-person-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f9b2.svg")
}
.twa-person-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4.svg")
}
.twa-person-biking {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4.svg")
}
.twa-person-biking-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-1f3ff.svg")
}
.twa-person-biking-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-1f3fb.svg")
}
.twa-person-biking-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-1f3fe.svg")
}
.twa-person-biking-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-1f3fc.svg")
}
.twa-person-biking-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-1f3fd.svg")
}
.twa-person-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471.svg")
}
.twa-person-bouncing-ball {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9.svg")
}
.twa-person-bouncing-ball-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-1f3ff.svg")
}
.twa-person-bouncing-ball-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-1f3fb.svg")
}
.twa-person-bouncing-ball-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-1f3fe.svg")
}
.twa-person-bouncing-ball-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-1f3fc.svg")
}
.twa-person-bouncing-ball-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-1f3fd.svg")
}
.twa-person-bowing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647.svg")
}
.twa-person-bowing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-1f3ff.svg")
}
.twa-person-bowing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-1f3fb.svg")
}
.twa-person-bowing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-1f3fe.svg")
}
.twa-person-bowing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-1f3fc.svg")
}
.twa-person-bowing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-1f3fd.svg")
}
.twa-person-cartwheeling {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938.svg")
}
.twa-person-cartwheeling-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-1f3ff.svg")
}
.twa-person-cartwheeling-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-1f3fb.svg")
}
.twa-person-cartwheeling-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-1f3fe.svg")
}
.twa-person-cartwheeling-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-1f3fc.svg")
}
.twa-person-cartwheeling-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-1f3fd.svg")
}
.twa-person-climbing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7.svg")
}
.twa-person-climbing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-1f3ff.svg")
}
.twa-person-climbing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-1f3fb.svg")
}
.twa-person-climbing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-1f3fe.svg")
}
.twa-person-climbing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-1f3fc.svg")
}
.twa-person-climbing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-1f3fd.svg")
}
.twa-person-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f9b1.svg")
}
.twa-person-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff.svg")
}
.twa-person-dark-skin-tone-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f9b2.svg")
}
.twa-person-dark-skin-tone-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-1f3ff.svg")
}
.twa-person-dark-skin-tone-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-1f3ff.svg")
}
.twa-person-dark-skin-tone-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f9b1.svg")
}
.twa-person-dark-skin-tone-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f9b0.svg")
}
.twa-person-dark-skin-tone-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f9b3.svg")
}
.twa-person-facepalming {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926.svg")
}
.twa-person-facepalming-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-1f3ff.svg")
}
.twa-person-facepalming-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-1f3fb.svg")
}
.twa-person-facepalming-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-1f3fe.svg")
}
.twa-person-facepalming-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-1f3fc.svg")
}
.twa-person-facepalming-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-1f3fd.svg")
}
.twa-person-feeding-baby {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f37c.svg")
}
.twa-person-feeding-baby-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f37c.svg")
}
.twa-person-feeding-baby-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f37c.svg")
}
.twa-person-feeding-baby-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f37c.svg")
}
.twa-person-feeding-baby-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f37c.svg")
}
.twa-person-feeding-baby-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f37c.svg")
}
.twa-person-fencing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93a.svg")
}
.twa-person-frowning {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d.svg")
}
.twa-person-frowning-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-1f3ff.svg")
}
.twa-person-frowning-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-1f3fb.svg")
}
.twa-person-frowning-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-1f3fe.svg")
}
.twa-person-frowning-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-1f3fc.svg")
}
.twa-person-frowning-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-1f3fd.svg")
}
.twa-person-gesturing-no {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645.svg")
}
.twa-person-gesturing-no-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-1f3ff.svg")
}
.twa-person-gesturing-no-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-1f3fb.svg")
}
.twa-person-gesturing-no-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-1f3fe.svg")
}
.twa-person-gesturing-no-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-1f3fc.svg")
}
.twa-person-gesturing-no-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-1f3fd.svg")
}
.twa-person-gesturing-ok {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646.svg")
}
.twa-person-gesturing-ok-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-1f3ff.svg")
}
.twa-person-gesturing-ok-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-1f3fb.svg")
}
.twa-person-gesturing-ok-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-1f3fe.svg")
}
.twa-person-gesturing-ok-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-1f3fc.svg")
}
.twa-person-gesturing-ok-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-1f3fd.svg")
}
.twa-person-getting-haircut {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487.svg")
}
.twa-person-getting-haircut-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-1f3ff.svg")
}
.twa-person-getting-haircut-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-1f3fb.svg")
}
.twa-person-getting-haircut-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-1f3fe.svg")
}
.twa-person-getting-haircut-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-1f3fc.svg")
}
.twa-person-getting-haircut-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-1f3fd.svg")
}
.twa-person-getting-massage {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486.svg")
}
.twa-person-getting-massage-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-1f3ff.svg")
}
.twa-person-getting-massage-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-1f3fb.svg")
}
.twa-person-getting-massage-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-1f3fe.svg")
}
.twa-person-getting-massage-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-1f3fc.svg")
}
.twa-person-getting-massage-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-1f3fd.svg")
}
.twa-person-golfing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc.svg")
}
.twa-person-golfing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-1f3ff.svg")
}
.twa-person-golfing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-1f3fb.svg")
}
.twa-person-golfing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-1f3fe.svg")
}
.twa-person-golfing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-1f3fc.svg")
}
.twa-person-golfing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-1f3fd.svg")
}
.twa-person-in-bed {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6cc.svg")
}
.twa-person-in-bed-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6cc-1f3ff.svg")
}
.twa-person-in-bed-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6cc-1f3fb.svg")
}
.twa-person-in-bed-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6cc-1f3fe.svg")
}
.twa-person-in-bed-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6cc-1f3fc.svg")
}
.twa-person-in-bed-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6cc-1f3fd.svg")
}
.twa-person-in-lotus-position {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8.svg")
}
.twa-person-in-lotus-position-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-1f3ff.svg")
}
.twa-person-in-lotus-position-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-1f3fb.svg")
}
.twa-person-in-lotus-position-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-1f3fe.svg")
}
.twa-person-in-lotus-position-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-1f3fc.svg")
}
.twa-person-in-lotus-position-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-1f3fd.svg")
}
.twa-person-in-manual-wheelchair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f9bd.svg")
}
.twa-person-in-manual-wheelchair-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f9bd.svg")
}
.twa-person-in-manual-wheelchair-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f9bd.svg")
}
.twa-person-in-manual-wheelchair-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f9bd.svg")
}
.twa-person-in-manual-wheelchair-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f9bd.svg")
}
.twa-person-in-manual-wheelchair-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f9bd.svg")
}
.twa-person-in-motorized-wheelchair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f9bc.svg")
}
.twa-person-in-motorized-wheelchair-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f9bc.svg")
}
.twa-person-in-motorized-wheelchair-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f9bc.svg")
}
.twa-person-in-motorized-wheelchair-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f9bc.svg")
}
.twa-person-in-motorized-wheelchair-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f9bc.svg")
}
.twa-person-in-motorized-wheelchair-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f9bc.svg")
}
.twa-person-in-steamy-room {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6.svg")
}
.twa-person-in-steamy-room-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-1f3ff.svg")
}
.twa-person-in-steamy-room-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-1f3fb.svg")
}
.twa-person-in-steamy-room-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-1f3fe.svg")
}
.twa-person-in-steamy-room-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-1f3fc.svg")
}
.twa-person-in-steamy-room-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-1f3fd.svg")
}
.twa-person-in-suit-levitating {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574.svg")
}
.twa-person-in-suit-levitating-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-1f3ff.svg")
}
.twa-person-in-suit-levitating-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-1f3fb.svg")
}
.twa-person-in-suit-levitating-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-1f3fe.svg")
}
.twa-person-in-suit-levitating-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-1f3fc.svg")
}
.twa-person-in-suit-levitating-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f574-1f3fd.svg")
}
.twa-person-in-tuxedo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935.svg")
}
.twa-person-in-tuxedo-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-1f3ff.svg")
}
.twa-person-in-tuxedo-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-1f3fb.svg")
}
.twa-person-in-tuxedo-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-1f3fe.svg")
}
.twa-person-in-tuxedo-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-1f3fc.svg")
}
.twa-person-in-tuxedo-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-1f3fd.svg")
}
.twa-person-juggling {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939.svg")
}
.twa-person-juggling-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-1f3ff.svg")
}
.twa-person-juggling-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-1f3fb.svg")
}
.twa-person-juggling-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-1f3fe.svg")
}
.twa-person-juggling-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-1f3fc.svg")
}
.twa-person-juggling-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-1f3fd.svg")
}
.twa-person-kneeling {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce.svg")
}
.twa-person-kneeling-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-1f3ff.svg")
}
.twa-person-kneeling-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-1f3fb.svg")
}
.twa-person-kneeling-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-1f3fe.svg")
}
.twa-person-kneeling-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-1f3fc.svg")
}
.twa-person-kneeling-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-1f3fd.svg")
}
.twa-person-lifting-weights {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb.svg")
}
.twa-person-lifting-weights-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-1f3ff.svg")
}
.twa-person-lifting-weights-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-1f3fb.svg")
}
.twa-person-lifting-weights-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-1f3fe.svg")
}
.twa-person-lifting-weights-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-1f3fc.svg")
}
.twa-person-lifting-weights-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-1f3fd.svg")
}
.twa-person-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb.svg")
}
.twa-person-light-skin-tone-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f9b2.svg")
}
.twa-person-light-skin-tone-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-1f3fb.svg")
}
.twa-person-light-skin-tone-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-1f3fb.svg")
}
.twa-person-light-skin-tone-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f9b1.svg")
}
.twa-person-light-skin-tone-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f9b0.svg")
}
.twa-person-light-skin-tone-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f9b3.svg")
}
.twa-person-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe.svg")
}
.twa-person-medium-dark-skin-tone-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f9b2.svg")
}
.twa-person-medium-dark-skin-tone-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-1f3fe.svg")
}
.twa-person-medium-dark-skin-tone-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-1f3fe.svg")
}
.twa-person-medium-dark-skin-tone-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f9b1.svg")
}
.twa-person-medium-dark-skin-tone-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f9b0.svg")
}
.twa-person-medium-dark-skin-tone-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f9b3.svg")
}
.twa-person-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc.svg")
}
.twa-person-medium-light-skin-tone-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f9b2.svg")
}
.twa-person-medium-light-skin-tone-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-1f3fc.svg")
}
.twa-person-medium-light-skin-tone-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-1f3fc.svg")
}
.twa-person-medium-light-skin-tone-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f9b1.svg")
}
.twa-person-medium-light-skin-tone-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f9b0.svg")
}
.twa-person-medium-light-skin-tone-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f9b3.svg")
}
.twa-person-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd.svg")
}
.twa-person-medium-skin-tone-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f9b2.svg")
}
.twa-person-medium-skin-tone-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-1f3fd.svg")
}
.twa-person-medium-skin-tone-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-1f3fd.svg")
}
.twa-person-medium-skin-tone-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f9b1.svg")
}
.twa-person-medium-skin-tone-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f9b0.svg")
}
.twa-person-medium-skin-tone-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f9b3.svg")
}
.twa-person-mountain-biking {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5.svg")
}
.twa-person-mountain-biking-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-1f3ff.svg")
}
.twa-person-mountain-biking-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-1f3fb.svg")
}
.twa-person-mountain-biking-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-1f3fe.svg")
}
.twa-person-mountain-biking-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-1f3fc.svg")
}
.twa-person-mountain-biking-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-1f3fd.svg")
}
.twa-person-playing-handball {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e.svg")
}
.twa-person-playing-handball-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-1f3ff.svg")
}
.twa-person-playing-handball-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-1f3fb.svg")
}
.twa-person-playing-handball-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-1f3fe.svg")
}
.twa-person-playing-handball-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-1f3fc.svg")
}
.twa-person-playing-handball-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-1f3fd.svg")
}
.twa-person-playing-water-polo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d.svg")
}
.twa-person-playing-water-polo-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-1f3ff.svg")
}
.twa-person-playing-water-polo-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-1f3fb.svg")
}
.twa-person-playing-water-polo-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-1f3fe.svg")
}
.twa-person-playing-water-polo-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-1f3fc.svg")
}
.twa-person-playing-water-polo-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-1f3fd.svg")
}
.twa-person-pouting {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e.svg")
}
.twa-person-pouting-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-1f3ff.svg")
}
.twa-person-pouting-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-1f3fb.svg")
}
.twa-person-pouting-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-1f3fe.svg")
}
.twa-person-pouting-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-1f3fc.svg")
}
.twa-person-pouting-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-1f3fd.svg")
}
.twa-person-raising-hand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b.svg")
}
.twa-person-raising-hand-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-1f3ff.svg")
}
.twa-person-raising-hand-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-1f3fb.svg")
}
.twa-person-raising-hand-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-1f3fe.svg")
}
.twa-person-raising-hand-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-1f3fc.svg")
}
.twa-person-raising-hand-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-1f3fd.svg")
}
.twa-person-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f9b0.svg")
}
.twa-person-rowing-boat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3.svg")
}
.twa-person-rowing-boat-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-1f3ff.svg")
}
.twa-person-rowing-boat-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-1f3fb.svg")
}
.twa-person-rowing-boat-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-1f3fe.svg")
}
.twa-person-rowing-boat-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-1f3fc.svg")
}
.twa-person-rowing-boat-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-1f3fd.svg")
}
.twa-person-running {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3.svg")
}
.twa-person-running-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-1f3ff.svg")
}
.twa-person-running-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-1f3fb.svg")
}
.twa-person-running-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-1f3fe.svg")
}
.twa-person-running-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-1f3fc.svg")
}
.twa-person-running-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-1f3fd.svg")
}
.twa-person-shrugging {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937.svg")
}
.twa-person-shrugging-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-1f3ff.svg")
}
.twa-person-shrugging-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-1f3fb.svg")
}
.twa-person-shrugging-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-1f3fe.svg")
}
.twa-person-shrugging-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-1f3fc.svg")
}
.twa-person-shrugging-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-1f3fd.svg")
}
.twa-person-standing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd.svg")
}
.twa-person-standing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-1f3ff.svg")
}
.twa-person-standing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-1f3fb.svg")
}
.twa-person-standing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-1f3fe.svg")
}
.twa-person-standing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-1f3fc.svg")
}
.twa-person-standing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-1f3fd.svg")
}
.twa-person-surfing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4.svg")
}
.twa-person-surfing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-1f3ff.svg")
}
.twa-person-surfing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-1f3fb.svg")
}
.twa-person-surfing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-1f3fe.svg")
}
.twa-person-surfing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-1f3fc.svg")
}
.twa-person-surfing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-1f3fd.svg")
}
.twa-person-swimming {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca.svg")
}
.twa-person-swimming-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-1f3ff.svg")
}
.twa-person-swimming-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-1f3fb.svg")
}
.twa-person-swimming-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-1f3fe.svg")
}
.twa-person-swimming-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-1f3fc.svg")
}
.twa-person-swimming-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-1f3fd.svg")
}
.twa-person-taking-bath {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6c0.svg")
}
.twa-person-taking-bath-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6c0-1f3ff.svg")
}
.twa-person-taking-bath-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6c0-1f3fb.svg")
}
.twa-person-taking-bath-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6c0-1f3fe.svg")
}
.twa-person-taking-bath-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6c0-1f3fc.svg")
}
.twa-person-taking-bath-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6c0-1f3fd.svg")
}
.twa-person-tipping-hand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481.svg")
}
.twa-person-tipping-hand-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-1f3ff.svg")
}
.twa-person-tipping-hand-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-1f3fb.svg")
}
.twa-person-tipping-hand-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-1f3fe.svg")
}
.twa-person-tipping-hand-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-1f3fc.svg")
}
.twa-person-tipping-hand-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-1f3fd.svg")
}
.twa-person-walking {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6.svg")
}
.twa-person-walking-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-1f3ff.svg")
}
.twa-person-walking-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-1f3fb.svg")
}
.twa-person-walking-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-1f3fe.svg")
}
.twa-person-walking-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-1f3fc.svg")
}
.twa-person-walking-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-1f3fd.svg")
}
.twa-person-wearing-turban {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473.svg")
}
.twa-person-wearing-turban-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-1f3ff.svg")
}
.twa-person-wearing-turban-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-1f3fb.svg")
}
.twa-person-wearing-turban-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-1f3fe.svg")
}
.twa-person-wearing-turban-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-1f3fc.svg")
}
.twa-person-wearing-turban-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-1f3fd.svg")
}
.twa-person-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f9b3.svg")
}
.twa-person-with-skullcap {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f472.svg")
}
.twa-person-with-skullcap-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f472-1f3ff.svg")
}
.twa-person-with-skullcap-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f472-1f3fb.svg")
}
.twa-person-with-skullcap-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f472-1f3fe.svg")
}
.twa-person-with-skullcap-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f472-1f3fc.svg")
}
.twa-person-with-skullcap-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f472-1f3fd.svg")
}
.twa-person-with-veil {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470.svg")
}
.twa-person-with-veil-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-1f3ff.svg")
}
.twa-person-with-veil-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-1f3fb.svg")
}
.twa-person-with-veil-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-1f3fe.svg")
}
.twa-person-with-veil-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-1f3fc.svg")
}
.twa-person-with-veil-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-1f3fd.svg")
}
.twa-person-with-white-cane {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f9af.svg")
}
.twa-person-with-white-cane-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f9af.svg")
}
.twa-person-with-white-cane-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f9af.svg")
}
.twa-person-with-white-cane-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f9af.svg")
}
.twa-person-with-white-cane-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f9af.svg")
}
.twa-person-with-white-cane-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f9af.svg")
}
.twa-petri-dish {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9eb.svg")
}
.twa-pi-ata {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa85.svg")
}
.twa-pick {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26cf.svg")
}
.twa-pickup-truck {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6fb.svg")
}
.twa-pie {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f967.svg")
}
.twa-pig {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f416.svg")
}
.twa-pig-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f437.svg")
}
.twa-pig-nose {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f43d.svg")
}
.twa-pile-of-poo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4a9.svg")
}
.twa-pill {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f48a.svg")
}
.twa-pilot {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-2708-fe0f.svg")
}
.twa-pilot-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-2708-fe0f.svg")
}
.twa-pilot-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-2708-fe0f.svg")
}
.twa-pilot-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-2708-fe0f.svg")
}
.twa-pilot-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-2708-fe0f.svg")
}
.twa-pilot-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-2708-fe0f.svg")
}
.twa-pinched-fingers {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f90c.svg")
}
.twa-pinched-fingers-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f90c-1f3ff.svg")
}
.twa-pinched-fingers-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f90c-1f3fb.svg")
}
.twa-pinched-fingers-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f90c-1f3fe.svg")
}
.twa-pinched-fingers-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f90c-1f3fc.svg")
}
.twa-pinched-fingers-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f90c-1f3fd.svg")
}
.twa-pinching-hand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f90f.svg")
}
.twa-pinching-hand-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f90f-1f3ff.svg")
}
.twa-pinching-hand-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f90f-1f3fb.svg")
}
.twa-pinching-hand-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f90f-1f3fe.svg")
}
.twa-pinching-hand-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f90f-1f3fc.svg")
}
.twa-pinching-hand-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f90f-1f3fd.svg")
}
.twa-pine-decoration {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f38d.svg")
}
.twa-pineapple {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f34d.svg")
}
.twa-ping-pong {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3d3.svg")
}
.twa-pirate-flag {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3f4-200d-2620-fe0f.svg")
}
.twa-pisces {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2653.svg")
}
.twa-pizza {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f355.svg")
}
.twa-placard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1faa7.svg")
}
.twa-place-of-worship {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6d0.svg")
}
.twa-play-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/25b6.svg")
}
.twa-play-or-pause-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/23ef.svg")
}
.twa-pleading-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f97a.svg")
}
.twa-plunger {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1faa0.svg")
}
.twa-plus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2795.svg")
}
.twa-polar-bear {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f43b-200d-2744-fe0f.svg")
}
.twa-police-car {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f693.svg")
}
.twa-police-car-light {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a8.svg")
}
.twa-police-officer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e.svg")
}
.twa-police-officer-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-1f3ff.svg")
}
.twa-police-officer-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-1f3fb.svg")
}
.twa-police-officer-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-1f3fe.svg")
}
.twa-police-officer-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-1f3fc.svg")
}
.twa-police-officer-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-1f3fd.svg")
}
.twa-poodle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f429.svg")
}
.twa-pool-8-ball {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3b1.svg")
}
.twa-popcorn {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f37f.svg")
}
.twa-post-office {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3e4.svg")
}
.twa-postal-horn {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4ef.svg")
}
.twa-postbox {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4ee.svg")
}
.twa-pot-of-food {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f372.svg")
}
.twa-potable-water {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b0.svg")
}
.twa-potato {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f954.svg")
}
.twa-potted-plant {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fab4.svg")
}
.twa-poultry-leg {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f357.svg")
}
.twa-pound-banknote {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4b7.svg")
}
.twa-pouting-cat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f63e.svg")
}
.twa-pouting-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f621.svg")
}
.twa-prayer-beads {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4ff.svg")
}
.twa-pregnant-woman {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f930.svg")
}
.twa-pregnant-woman-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f930-1f3ff.svg")
}
.twa-pregnant-woman-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f930-1f3fb.svg")
}
.twa-pregnant-woman-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f930-1f3fe.svg")
}
.twa-pregnant-woman-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f930-1f3fc.svg")
}
.twa-pregnant-woman-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f930-1f3fd.svg")
}
.twa-pretzel {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f968.svg")
}
.twa-prince {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f934.svg")
}
.twa-prince-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f934-1f3ff.svg")
}
.twa-prince-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f934-1f3fb.svg")
}
.twa-prince-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f934-1f3fe.svg")
}
.twa-prince-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f934-1f3fc.svg")
}
.twa-prince-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f934-1f3fd.svg")
}
.twa-princess {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f478.svg")
}
.twa-princess-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f478-1f3ff.svg")
}
.twa-princess-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f478-1f3fb.svg")
}
.twa-princess-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f478-1f3fe.svg")
}
.twa-princess-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f478-1f3fc.svg")
}
.twa-princess-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f478-1f3fd.svg")
}
.twa-printer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5a8.svg")
}
.twa-prohibited {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6ab.svg")
}
.twa-purple-circle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f7e3.svg")
}
.twa-purple-heart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f49c.svg")
}
.twa-purple-square {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f7ea.svg")
}
.twa-purse {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f45b.svg")
}
.twa-pushpin {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4cc.svg")
}
.twa-puzzle-piece {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9e9.svg")
}
.twa-rabbit {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f407.svg")
}
.twa-rabbit-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f430.svg")
}
.twa-raccoon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f99d.svg")
}
.twa-racing-car {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ce.svg")
}
.twa-radio {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4fb.svg")
}
.twa-radio-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f518.svg")
}
.twa-radioactive {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2622.svg")
}
.twa-railway-car {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f683.svg")
}
.twa-railway-track {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6e4.svg")
}
.twa-rainbow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f308.svg")
}
.twa-rainbow-flag {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3f3-fe0f-200d-1f308.svg")
}
.twa-raised-back-of-hand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91a.svg")
}
.twa-raised-back-of-hand-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91a-1f3ff.svg")
}
.twa-raised-back-of-hand-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91a-1f3fb.svg")
}
.twa-raised-back-of-hand-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91a-1f3fe.svg")
}
.twa-raised-back-of-hand-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91a-1f3fc.svg")
}
.twa-raised-back-of-hand-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91a-1f3fd.svg")
}
.twa-raised-fist {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270a.svg")
}
.twa-raised-fist-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270a-1f3ff.svg")
}
.twa-raised-fist-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270a-1f3fb.svg")
}
.twa-raised-fist-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270a-1f3fe.svg")
}
.twa-raised-fist-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270a-1f3fc.svg")
}
.twa-raised-fist-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270a-1f3fd.svg")
}
.twa-raised-hand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270b.svg")
}
.twa-raised-hand-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270b-1f3ff.svg")
}
.twa-raised-hand-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270b-1f3fb.svg")
}
.twa-raised-hand-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270b-1f3fe.svg")
}
.twa-raised-hand-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270b-1f3fc.svg")
}
.twa-raised-hand-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270b-1f3fd.svg")
}
.twa-raising-hands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64c.svg")
}
.twa-raising-hands-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64c-1f3ff.svg")
}
.twa-raising-hands-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64c-1f3fb.svg")
}
.twa-raising-hands-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64c-1f3fe.svg")
}
.twa-raising-hands-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64c-1f3fc.svg")
}
.twa-raising-hands-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64c-1f3fd.svg")
}
.twa-ram {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f40f.svg")
}
.twa-rat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f400.svg")
}
.twa-razor {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa92.svg")
}
.twa-receipt {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9fe.svg")
}
.twa-record-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/23fa.svg")
}
.twa-recycling-symbol {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/267b.svg")
}
.twa-red-apple {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f34e.svg")
}
.twa-red-circle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f534.svg")
}
.twa-red-envelope {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9e7.svg")
}
.twa-red-exclamation-mark {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2757.svg")
}
.twa-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b0.svg")
}
.twa-red-heart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2764.svg")
}
.twa-red-paper-lantern {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ee.svg")
}
.twa-red-question-mark {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2753.svg")
}
.twa-red-square {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f7e5.svg")
}
.twa-red-triangle-pointed-down {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f53b.svg")
}
.twa-red-triangle-pointed-up {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f53a.svg")
}
.twa-regional-indicator-symbol-letter-a {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e6.svg")
}
.twa-regional-indicator-symbol-letter-b {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e7.svg")
}
.twa-regional-indicator-symbol-letter-c {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e8.svg")
}
.twa-regional-indicator-symbol-letter-d {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1e9.svg")
}
.twa-regional-indicator-symbol-letter-e {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ea.svg")
}
.twa-regional-indicator-symbol-letter-f {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1eb.svg")
}
.twa-regional-indicator-symbol-letter-g {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ec.svg")
}
.twa-regional-indicator-symbol-letter-h {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ed.svg")
}
.twa-regional-indicator-symbol-letter-i {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ee.svg")
}
.twa-regional-indicator-symbol-letter-j {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ef.svg")
}
.twa-regional-indicator-symbol-letter-k {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f0.svg")
}
.twa-regional-indicator-symbol-letter-l {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f1.svg")
}
.twa-regional-indicator-symbol-letter-m {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f2.svg")
}
.twa-regional-indicator-symbol-letter-n {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f3.svg")
}
.twa-regional-indicator-symbol-letter-o {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f4.svg")
}
.twa-regional-indicator-symbol-letter-p {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f5.svg")
}
.twa-regional-indicator-symbol-letter-q {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f6.svg")
}
.twa-regional-indicator-symbol-letter-r {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f7.svg")
}
.twa-regional-indicator-symbol-letter-s {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f8.svg")
}
.twa-regional-indicator-symbol-letter-t {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1f9.svg")
}
.twa-regional-indicator-symbol-letter-u {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fa.svg")
}
.twa-regional-indicator-symbol-letter-v {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fb.svg")
}
.twa-regional-indicator-symbol-letter-w {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fc.svg")
}
.twa-regional-indicator-symbol-letter-x {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fd.svg")
}
.twa-regional-indicator-symbol-letter-y {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1fe.svg")
}
.twa-regional-indicator-symbol-letter-z {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f1ff.svg")
}
.twa-registered {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/ae.svg")
}
.twa-relieved-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f60c.svg")
}
.twa-reminder-ribbon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f397.svg")
}
.twa-repeat-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f501.svg")
}
.twa-repeat-single-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f502.svg")
}
.twa-rescue-worker-s-helmet {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26d1.svg")
}
.twa-restroom {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6bb.svg")
}
.twa-reverse-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/25c0.svg")
}
.twa-revolving-hearts {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f49e.svg")
}
.twa-rhinoceros {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f98f.svg")
}
.twa-ribbon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f380.svg")
}
.twa-rice-ball {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f359.svg")
}
.twa-rice-cracker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f358.svg")
}
.twa-right-anger-bubble {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5ef.svg")
}
.twa-right-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/27a1.svg")
}
.twa-right-arrow-curving-down {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2935.svg")
}
.twa-right-arrow-curving-left {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/21a9.svg")
}
.twa-right-arrow-curving-up {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2934.svg")
}
.twa-right-facing-fist {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91c.svg")
}
.twa-right-facing-fist-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91c-1f3ff.svg")
}
.twa-right-facing-fist-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91c-1f3fb.svg")
}
.twa-right-facing-fist-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91c-1f3fe.svg")
}
.twa-right-facing-fist-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91c-1f3fc.svg")
}
.twa-right-facing-fist-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f91c-1f3fd.svg")
}
.twa-ring {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f48d.svg")
}
.twa-ringed-planet {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa90.svg")
}
.twa-roasted-sweet-potato {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f360.svg")
}
.twa-robot {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f916.svg")
}
.twa-rock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1faa8.svg")
}
.twa-rocket {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f680.svg")
}
.twa-roll-of-paper {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9fb.svg")
}
.twa-rolled-up-newspaper {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5de.svg")
}
.twa-roller-coaster {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3a2.svg")
}
.twa-roller-skate {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6fc.svg")
}
.twa-rolling-on-the-floor-laughing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f923.svg")
}
.twa-rooster {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f413.svg")
}
.twa-rose {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f339.svg")
}
.twa-rosette {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3f5.svg")
}
.twa-round-pushpin {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4cd.svg")
}
.twa-rugby-football {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c9.svg")
}
.twa-running-shirt {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3bd.svg")
}
.twa-running-shoe {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f45f.svg")
}
.twa-sad-but-relieved-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f625.svg")
}
.twa-safety-pin {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9f7.svg")
}
.twa-safety-vest {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ba.svg")
}
.twa-sagittarius {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2650.svg")
}
.twa-sailboat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f5.svg")
}
.twa-sake {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f376.svg")
}
.twa-salt {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9c2.svg")
}
.twa-sandwich {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f96a.svg")
}
.twa-santa-claus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f385.svg")
}
.twa-santa-claus-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f385-1f3ff.svg")
}
.twa-santa-claus-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f385-1f3fb.svg")
}
.twa-santa-claus-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f385-1f3fe.svg")
}
.twa-santa-claus-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f385-1f3fc.svg")
}
.twa-santa-claus-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f385-1f3fd.svg")
}
.twa-sari {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f97b.svg")
}
.twa-satellite {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6f0.svg")
}
.twa-satellite-antenna {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4e1.svg")
}
.twa-sauropod {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f995.svg")
}
.twa-saxophone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3b7.svg")
}
.twa-scarf {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9e3.svg")
}
.twa-school {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3eb.svg")
}
.twa-scientist {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f52c.svg")
}
.twa-scientist-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f52c.svg")
}
.twa-scientist-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f52c.svg")
}
.twa-scientist-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f52c.svg")
}
.twa-scientist-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f52c.svg")
}
.twa-scientist-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f52c.svg")
}
.twa-scissors {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2702.svg")
}
.twa-scorpio {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/264f.svg")
}
.twa-scorpion {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f982.svg")
}
.twa-screwdriver {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa9b.svg")
}
.twa-scroll {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4dc.svg")
}
.twa-seal {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ad.svg")
}
.twa-seat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4ba.svg")
}
.twa-see-no-evil-monkey {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f648.svg")
}
.twa-seedling {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f331.svg")
}
.twa-selfie {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f933.svg")
}
.twa-selfie-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f933-1f3ff.svg")
}
.twa-selfie-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f933-1f3fb.svg")
}
.twa-selfie-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f933-1f3fe.svg")
}
.twa-selfie-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f933-1f3fc.svg")
}
.twa-selfie-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f933-1f3fd.svg")
}
.twa-service-dog {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f415-200d-1f9ba.svg")
}
.twa-seven-o-clock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f556.svg")
}
.twa-seven-thirty {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f562.svg")
}
.twa-sewing-needle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1faa1.svg")
}
.twa-shallow-pan-of-food {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f958.svg")
}
.twa-shamrock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2618.svg")
}
.twa-shark {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f988.svg")
}
.twa-shaved-ice {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f367.svg")
}
.twa-sheaf-of-rice {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f33e.svg")
}
.twa-shibuya {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/e50a.svg")
}
.twa-shield {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6e1.svg")
}
.twa-shinto-shrine {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26e9.svg")
}
.twa-ship {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a2.svg")
}
.twa-shooting-star {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f320.svg")
}
.twa-shopping-bags {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6cd.svg")
}
.twa-shopping-cart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6d2.svg")
}
.twa-shortcake {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f370.svg")
}
.twa-shorts {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa73.svg")
}
.twa-shower {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6bf.svg")
}
.twa-shrimp {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f990.svg")
}
.twa-shuffle-tracks-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f500.svg")
}
.twa-shushing-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f92b.svg")
}
.twa-sign-of-the-horns {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f918.svg")
}
.twa-sign-of-the-horns-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f918-1f3ff.svg")
}
.twa-sign-of-the-horns-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f918-1f3fb.svg")
}
.twa-sign-of-the-horns-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f918-1f3fe.svg")
}
.twa-sign-of-the-horns-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f918-1f3fc.svg")
}
.twa-sign-of-the-horns-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f918-1f3fd.svg")
}
.twa-singer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f3a4.svg")
}
.twa-singer-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f3a4.svg")
}
.twa-singer-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f3a4.svg")
}
.twa-singer-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f3a4.svg")
}
.twa-singer-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f3a4.svg")
}
.twa-singer-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f3a4.svg")
}
.twa-six-o-clock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f555.svg")
}
.twa-six-thirty {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f561.svg")
}
.twa-skateboard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6f9.svg")
}
.twa-skier {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f7.svg")
}
.twa-skier-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f7-1f3ff.svg")
}
.twa-skier-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f7-1f3fb.svg")
}
.twa-skier-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f7-1f3fe.svg")
}
.twa-skier-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f7-1f3fc.svg")
}
.twa-skier-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f7-1f3fd.svg")
}
.twa-skis {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3bf.svg")
}
.twa-skull {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f480.svg")
}
.twa-skull-and-crossbones {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2620.svg")
}
.twa-skunk {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9a8.svg")
}
.twa-sled {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6f7.svg")
}
.twa-sleeping-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f634.svg")
}
.twa-sleepy-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f62a.svg")
}
.twa-slightly-frowning-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f641.svg")
}
.twa-slightly-smiling-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f642.svg")
}
.twa-slot-machine {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3b0.svg")
}
.twa-sloth {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9a5.svg")
}
.twa-small-airplane {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6e9.svg")
}
.twa-small-blue-diamond {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f539.svg")
}
.twa-small-orange-diamond {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f538.svg")
}
.twa-smiling-cat-with-heart-eyes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f63b.svg")
}
.twa-smiling-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/263a.svg")
}
.twa-smiling-face-with-halo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f607.svg")
}
.twa-smiling-face-with-heart-eyes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f60d.svg")
}
.twa-smiling-face-with-hearts {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f970.svg")
}
.twa-smiling-face-with-horns {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f608.svg")
}
.twa-smiling-face-with-smiling-eyes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f60a.svg")
}
.twa-smiling-face-with-sunglasses {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f60e.svg")
}
.twa-smiling-face-with-tear {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f972.svg")
}
.twa-smirking-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f60f.svg")
}
.twa-snail {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f40c.svg")
}
.twa-snake {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f40d.svg")
}
.twa-sneezing-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f927.svg")
}
.twa-snow-capped-mountain {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3d4.svg")
}
.twa-snowboarder {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c2.svg")
}
.twa-snowboarder-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c2-1f3ff.svg")
}
.twa-snowboarder-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c2-1f3fb.svg")
}
.twa-snowboarder-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c2-1f3fe.svg")
}
.twa-snowboarder-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c2-1f3fc.svg")
}
.twa-snowboarder-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c2-1f3fd.svg")
}
.twa-snowflake {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2744.svg")
}
.twa-snowman {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2603.svg")
}
.twa-snowman-without-snow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26c4.svg")
}
.twa-soap {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9fc.svg")
}
.twa-soccer-ball {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26bd.svg")
}
.twa-socks {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9e6.svg")
}
.twa-soft-ice-cream {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f366.svg")
}
.twa-softball {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f94e.svg")
}
.twa-soon-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f51c.svg")
}
.twa-sos-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f198.svg")
}
.twa-spade-suit {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2660.svg")
}
.twa-spaghetti {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f35d.svg")
}
.twa-sparkle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2747.svg")
}
.twa-sparkler {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f387.svg")
}
.twa-sparkles {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2728.svg")
}
.twa-sparkling-heart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f496.svg")
}
.twa-speak-no-evil-monkey {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64a.svg")
}
.twa-speaker-high-volume {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f50a.svg")
}
.twa-speaker-low-volume {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f508.svg")
}
.twa-speaker-medium-volume {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f509.svg")
}
.twa-speaking-head {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5e3.svg")
}
.twa-speech-balloon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4ac.svg")
}
.twa-speedboat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a4.svg")
}
.twa-spider {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f577.svg")
}
.twa-spider-web {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f578.svg")
}
.twa-spiral-calendar {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5d3.svg")
}
.twa-spiral-notepad {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5d2.svg")
}
.twa-spiral-shell {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f41a.svg")
}
.twa-sponge {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9fd.svg")
}
.twa-spoon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f944.svg")
}
.twa-sport-utility-vehicle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f699.svg")
}
.twa-sports-medal {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c5.svg")
}
.twa-spouting-whale {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f433.svg")
}
.twa-squid {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f991.svg")
}
.twa-squinting-face-with-tongue {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f61d.svg")
}
.twa-stadium {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3df.svg")
}
.twa-star {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2b50.svg")
}
.twa-star-and-crescent {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/262a.svg")
}
.twa-star-of-david {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2721.svg")
}
.twa-star-struck {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f929.svg")
}
.twa-station {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f689.svg")
}
.twa-statue-of-liberty {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5fd.svg")
}
.twa-steaming-bowl {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f35c.svg")
}
.twa-stethoscope {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa7a.svg")
}
.twa-stop-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/23f9.svg")
}
.twa-stop-sign {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6d1.svg")
}
.twa-stopwatch {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/23f1.svg")
}
.twa-straight-ruler {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4cf.svg")
}
.twa-strawberry {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f353.svg")
}
.twa-student {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f393.svg")
}
.twa-student-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f393.svg")
}
.twa-student-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f393.svg")
}
.twa-student-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f393.svg")
}
.twa-student-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f393.svg")
}
.twa-student-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f393.svg")
}
.twa-studio-microphone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f399.svg")
}
.twa-stuffed-flatbread {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f959.svg")
}
.twa-sun {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2600.svg")
}
.twa-sun-behind-cloud {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26c5.svg")
}
.twa-sun-behind-large-cloud {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f325.svg")
}
.twa-sun-behind-rain-cloud {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f326.svg")
}
.twa-sun-behind-small-cloud {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f324.svg")
}
.twa-sun-with-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f31e.svg")
}
.twa-sunflower {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f33b.svg")
}
.twa-sunglasses {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f576.svg")
}
.twa-sunrise {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f305.svg")
}
.twa-sunrise-over-mountains {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f304.svg")
}
.twa-sunset {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f307.svg")
}
.twa-superhero {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8.svg")
}
.twa-superhero-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-1f3ff.svg")
}
.twa-superhero-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-1f3fb.svg")
}
.twa-superhero-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-1f3fe.svg")
}
.twa-superhero-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-1f3fc.svg")
}
.twa-superhero-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-1f3fd.svg")
}
.twa-supervillain {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9.svg")
}
.twa-supervillain-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-1f3ff.svg")
}
.twa-supervillain-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-1f3fb.svg")
}
.twa-supervillain-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-1f3fe.svg")
}
.twa-supervillain-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-1f3fc.svg")
}
.twa-supervillain-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-1f3fd.svg")
}
.twa-sushi {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f363.svg")
}
.twa-suspension-railway {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f69f.svg")
}
.twa-swan {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9a2.svg")
}
.twa-sweat-droplets {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4a6.svg")
}
.twa-synagogue {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f54d.svg")
}
.twa-syringe {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f489.svg")
}
.twa-t-rex {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f996.svg")
}
.twa-t-shirt {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f455.svg")
}
.twa-taco {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f32e.svg")
}
.twa-takeout-box {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f961.svg")
}
.twa-tamale {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fad4.svg")
}
.twa-tanabata-tree {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f38b.svg")
}
.twa-tangerine {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f34a.svg")
}
.twa-taurus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2649.svg")
}
.twa-taxi {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f695.svg")
}
.twa-teacher {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f3eb.svg")
}
.twa-teacher-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f3eb.svg")
}
.twa-teacher-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f3eb.svg")
}
.twa-teacher-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f3eb.svg")
}
.twa-teacher-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f3eb.svg")
}
.twa-teacher-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f3eb.svg")
}
.twa-teacup-without-handle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f375.svg")
}
.twa-teapot {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fad6.svg")
}
.twa-tear-off-calendar {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4c6.svg")
}
.twa-technologist {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-200d-1f4bb.svg")
}
.twa-technologist-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3ff-200d-1f4bb.svg")
}
.twa-technologist-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fb-200d-1f4bb.svg")
}
.twa-technologist-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fe-200d-1f4bb.svg")
}
.twa-technologist-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fc-200d-1f4bb.svg")
}
.twa-technologist-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d1-1f3fd-200d-1f4bb.svg")
}
.twa-teddy-bear {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9f8.svg")
}
.twa-telephone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/260e.svg")
}
.twa-telephone-receiver {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4de.svg")
}
.twa-telescope {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f52d.svg")
}
.twa-television {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4fa.svg")
}
.twa-ten-o-clock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f559.svg")
}
.twa-ten-thirty {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f565.svg")
}
.twa-tennis {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3be.svg")
}
.twa-tent {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26fa.svg")
}
.twa-test-tube {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ea.svg")
}
.twa-thermometer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f321.svg")
}
.twa-thinking-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f914.svg")
}
.twa-thong-sandal {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa74.svg")
}
.twa-thought-balloon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4ad.svg")
}
.twa-thread {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9f5.svg")
}
.twa-three-o-clock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f552.svg")
}
.twa-three-thirty {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f55e.svg")
}
.twa-thumbs-down {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44e.svg")
}
.twa-thumbs-down-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44e-1f3ff.svg")
}
.twa-thumbs-down-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44e-1f3fb.svg")
}
.twa-thumbs-down-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44e-1f3fe.svg")
}
.twa-thumbs-down-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44e-1f3fc.svg")
}
.twa-thumbs-down-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44e-1f3fd.svg")
}
.twa-thumbs-up {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44d.svg")
}
.twa-thumbs-up-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44d-1f3ff.svg")
}
.twa-thumbs-up-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44d-1f3fb.svg")
}
.twa-thumbs-up-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44d-1f3fe.svg")
}
.twa-thumbs-up-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44d-1f3fc.svg")
}
.twa-thumbs-up-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44d-1f3fd.svg")
}
.twa-ticket {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ab.svg")
}
.twa-tiger {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f405.svg")
}
.twa-tiger-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f42f.svg")
}
.twa-timer-clock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/23f2.svg")
}
.twa-tired-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f62b.svg")
}
.twa-toilet {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6bd.svg")
}
.twa-tokyo-tower {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5fc.svg")
}
.twa-tomato {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f345.svg")
}
.twa-tongue {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f445.svg")
}
.twa-toolbox {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9f0.svg")
}
.twa-tooth {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b7.svg")
}
.twa-toothbrush {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1faa5.svg")
}
.twa-top-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f51d.svg")
}
.twa-top-hat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3a9.svg")
}
.twa-tornado {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f32a.svg")
}
.twa-trackball {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5b2.svg")
}
.twa-tractor {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f69c.svg")
}
.twa-trade-mark {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2122.svg")
}
.twa-train {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f686.svg")
}
.twa-tram {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f68a.svg")
}
.twa-tram-car {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f68b.svg")
}
.twa-transgender-flag {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3f3-fe0f-200d-26a7-fe0f.svg")
}
.twa-transgender-symbol {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26a7.svg")
}
.twa-triangular-flag {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a9.svg")
}
.twa-triangular-ruler {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4d0.svg")
}
.twa-trident-emblem {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f531.svg")
}
.twa-trolleybus {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f68e.svg")
}
.twa-trophy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c6.svg")
}
.twa-tropical-drink {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f379.svg")
}
.twa-tropical-fish {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f420.svg")
}
.twa-trumpet {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ba.svg")
}
.twa-tulip {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f337.svg")
}
.twa-tumbler-glass {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f943.svg")
}
.twa-turkey {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f983.svg")
}
.twa-turtle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f422.svg")
}
.twa-twelve-o-clock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f55b.svg")
}
.twa-twelve-thirty {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f567.svg")
}
.twa-two-hearts {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f495.svg")
}
.twa-two-hump-camel {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f42b.svg")
}
.twa-two-o-clock {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f551.svg")
}
.twa-two-thirty {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f55d.svg")
}
.twa-umbrella {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2602.svg")
}
.twa-umbrella-on-ground {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f1.svg")
}
.twa-umbrella-with-rain-drops {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2614.svg")
}
.twa-unamused-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f612.svg")
}
.twa-unicorn {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f984.svg")
}
.twa-unlocked {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f513.svg")
}
.twa-up-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2b06.svg")
}
.twa-up-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f199.svg")
}
.twa-up-down-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2195.svg")
}
.twa-up-left-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2196.svg")
}
.twa-up-right-arrow {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2197.svg")
}
.twa-upside-down-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f643.svg")
}
.twa-upwards-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f53c.svg")
}
.twa-vampire {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db.svg")
}
.twa-vampire-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-1f3ff.svg")
}
.twa-vampire-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-1f3fb.svg")
}
.twa-vampire-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-1f3fe.svg")
}
.twa-vampire-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-1f3fc.svg")
}
.twa-vampire-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-1f3fd.svg")
}
.twa-vertical-traffic-light {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a6.svg")
}
.twa-vibration-mode {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4f3.svg")
}
.twa-victory-hand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270c.svg")
}
.twa-victory-hand-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270c-1f3ff.svg")
}
.twa-victory-hand-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270c-1f3fb.svg")
}
.twa-victory-hand-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270c-1f3fe.svg")
}
.twa-victory-hand-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270c-1f3fc.svg")
}
.twa-victory-hand-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270c-1f3fd.svg")
}
.twa-video-camera {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4f9.svg")
}
.twa-video-game {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ae.svg")
}
.twa-videocassette {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4fc.svg")
}
.twa-violin {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3bb.svg")
}
.twa-virgo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/264d.svg")
}
.twa-volcano {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f30b.svg")
}
.twa-volleyball {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3d0.svg")
}
.twa-vs-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f19a.svg")
}
.twa-vulcan-salute {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f596.svg")
}
.twa-vulcan-salute-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f596-1f3ff.svg")
}
.twa-vulcan-salute-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f596-1f3fb.svg")
}
.twa-vulcan-salute-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f596-1f3fe.svg")
}
.twa-vulcan-salute-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f596-1f3fc.svg")
}
.twa-vulcan-salute-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f596-1f3fd.svg")
}
.twa-waffle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9c7.svg")
}
.twa-waning-crescent-moon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f318.svg")
}
.twa-waning-gibbous-moon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f316.svg")
}
.twa-warning {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26a0.svg")
}
.twa-wastebasket {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5d1.svg")
}
.twa-watch {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/231a.svg")
}
.twa-water-buffalo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f403.svg")
}
.twa-water-closet {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6be.svg")
}
.twa-water-pistol {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f52b.svg")
}
.twa-water-wave {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f30a.svg")
}
.twa-watermelon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f349.svg")
}
.twa-waving-hand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44b.svg")
}
.twa-waving-hand-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44b-1f3ff.svg")
}
.twa-waving-hand-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44b-1f3fb.svg")
}
.twa-waving-hand-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44b-1f3fe.svg")
}
.twa-waving-hand-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44b-1f3fc.svg")
}
.twa-waving-hand-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f44b-1f3fd.svg")
}
.twa-wavy-dash {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/3030.svg")
}
.twa-waxing-crescent-moon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f312.svg")
}
.twa-waxing-gibbous-moon {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f314.svg")
}
.twa-weary-cat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f640.svg")
}
.twa-weary-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f629.svg")
}
.twa-wedding {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f492.svg")
}
.twa-whale {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f40b.svg")
}
.twa-wheel-of-dharma {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2638.svg")
}
.twa-wheelchair-symbol {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/267f.svg")
}
.twa-white-cane {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9af.svg")
}
.twa-white-circle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26aa.svg")
}
.twa-white-exclamation-mark {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2755.svg")
}
.twa-white-flag {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3f3.svg")
}
.twa-white-flower {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4ae.svg")
}
.twa-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b3.svg")
}
.twa-white-heart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f90d.svg")
}
.twa-white-large-square {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2b1c.svg")
}
.twa-white-medium-small-square {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/25fd.svg")
}
.twa-white-medium-square {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/25fb.svg")
}
.twa-white-question-mark {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/2754.svg")
}
.twa-white-small-square {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/25ab.svg")
}
.twa-white-square-button {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f533.svg")
}
.twa-wilted-flower {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f940.svg")
}
.twa-wind-chime {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f390.svg")
}
.twa-wind-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f32c.svg")
}
.twa-window {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa9f.svg")
}
.twa-wine-glass {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f377.svg")
}
.twa-winking-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f609.svg")
}
.twa-winking-face-with-tongue {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f61c.svg")
}
.twa-wolf {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f43a.svg")
}
.twa-woman {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469.svg")
}
.twa-woman-and-man-holding-hands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46b.svg")
}
.twa-woman-and-man-holding-hands-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46b-1f3ff.svg")
}
.twa-woman-and-man-holding-hands-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f91d-200d-1f468-1f3fb.svg")
}
.twa-woman-and-man-holding-hands-dark-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f91d-200d-1f468-1f3fe.svg")
}
.twa-woman-and-man-holding-hands-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f91d-200d-1f468-1f3fc.svg")
}
.twa-woman-and-man-holding-hands-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f91d-200d-1f468-1f3fd.svg")
}
.twa-woman-and-man-holding-hands-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46b-1f3fb.svg")
}
.twa-woman-and-man-holding-hands-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f91d-200d-1f468-1f3ff.svg")
}
.twa-woman-and-man-holding-hands-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f91d-200d-1f468-1f3fe.svg")
}
.twa-woman-and-man-holding-hands-light-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f91d-200d-1f468-1f3fc.svg")
}
.twa-woman-and-man-holding-hands-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f91d-200d-1f468-1f3fd.svg")
}
.twa-woman-and-man-holding-hands-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46b-1f3fe.svg")
}
.twa-woman-and-man-holding-hands-medium-dark-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f91d-200d-1f468-1f3ff.svg")
}
.twa-woman-and-man-holding-hands-medium-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f91d-200d-1f468-1f3fb.svg")
}
.twa-woman-and-man-holding-hands-medium-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f91d-200d-1f468-1f3fc.svg")
}
.twa-woman-and-man-holding-hands-medium-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f91d-200d-1f468-1f3fd.svg")
}
.twa-woman-and-man-holding-hands-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46b-1f3fc.svg")
}
.twa-woman-and-man-holding-hands-medium-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f91d-200d-1f468-1f3ff.svg")
}
.twa-woman-and-man-holding-hands-medium-light-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f91d-200d-1f468-1f3fb.svg")
}
.twa-woman-and-man-holding-hands-medium-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f91d-200d-1f468-1f3fe.svg")
}
.twa-woman-and-man-holding-hands-medium-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f91d-200d-1f468-1f3fd.svg")
}
.twa-woman-and-man-holding-hands-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46b-1f3fd.svg")
}
.twa-woman-and-man-holding-hands-medium-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f91d-200d-1f468-1f3ff.svg")
}
.twa-woman-and-man-holding-hands-medium-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f91d-200d-1f468-1f3fb.svg")
}
.twa-woman-and-man-holding-hands-medium-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f91d-200d-1f468-1f3fe.svg")
}
.twa-woman-and-man-holding-hands-medium-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f91d-200d-1f468-1f3fc.svg")
}
.twa-woman-artist {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f3a8.svg")
}
.twa-woman-artist-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f3a8.svg")
}
.twa-woman-artist-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f3a8.svg")
}
.twa-woman-artist-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f3a8.svg")
}
.twa-woman-artist-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f3a8.svg")
}
.twa-woman-artist-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f3a8.svg")
}
.twa-woman-astronaut {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f680.svg")
}
.twa-woman-astronaut-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f680.svg")
}
.twa-woman-astronaut-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f680.svg")
}
.twa-woman-astronaut-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f680.svg")
}
.twa-woman-astronaut-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f680.svg")
}
.twa-woman-astronaut-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f680.svg")
}
.twa-woman-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f9b2.svg")
}
.twa-woman-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-200d-2640-fe0f.svg")
}
.twa-woman-biking {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-200d-2640-fe0f.svg")
}
.twa-woman-biking-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-biking-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-biking-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-biking-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-biking-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b4-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-200d-2640-fe0f.svg")
}
.twa-woman-bouncing-ball {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-fe0f-200d-2640-fe0f.svg")
}
.twa-woman-bouncing-ball-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-bouncing-ball-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-bouncing-ball-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-bouncing-ball-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-bouncing-ball-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/26f9-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-bowing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-200d-2640-fe0f.svg")
}
.twa-woman-bowing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-bowing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-bowing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-bowing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-bowing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f647-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-cartwheeling {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-200d-2640-fe0f.svg")
}
.twa-woman-cartwheeling-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-cartwheeling-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-cartwheeling-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-cartwheeling-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-cartwheeling-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f938-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-christmas {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f384.svg")
}
.twa-woman-climbing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-200d-2640-fe0f.svg")
}
.twa-woman-climbing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-climbing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-climbing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-climbing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-climbing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d7-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-construction-worker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-200d-2640-fe0f.svg")
}
.twa-woman-construction-worker-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-construction-worker-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-construction-worker-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-construction-worker-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-construction-worker-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f477-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-cook {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f373.svg")
}
.twa-woman-cook-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f373.svg")
}
.twa-woman-cook-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f373.svg")
}
.twa-woman-cook-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f373.svg")
}
.twa-woman-cook-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f373.svg")
}
.twa-woman-cook-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f373.svg")
}
.twa-woman-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f9b1.svg")
}
.twa-woman-dancing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f483.svg")
}
.twa-woman-dancing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f483-1f3ff.svg")
}
.twa-woman-dancing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f483-1f3fb.svg")
}
.twa-woman-dancing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f483-1f3fe.svg")
}
.twa-woman-dancing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f483-1f3fc.svg")
}
.twa-woman-dancing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f483-1f3fd.svg")
}
.twa-woman-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff.svg")
}
.twa-woman-dark-skin-tone-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f9b2.svg")
}
.twa-woman-dark-skin-tone-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-dark-skin-tone-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-dark-skin-tone-christmas {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f384.svg")
}
.twa-woman-dark-skin-tone-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f9b1.svg")
}
.twa-woman-dark-skin-tone-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f9b0.svg")
}
.twa-woman-dark-skin-tone-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f9b3.svg")
}
.twa-woman-detective {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-fe0f-200d-2640-fe0f.svg")
}
.twa-woman-detective-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-detective-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-detective-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-detective-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-detective-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f575-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-elf {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-200d-2640-fe0f.svg")
}
.twa-woman-elf-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-elf-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-elf-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-elf-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-elf-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9dd-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-facepalming {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-200d-2640-fe0f.svg")
}
.twa-woman-facepalming-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-facepalming-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-facepalming-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-facepalming-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-facepalming-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f926-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-factory-worker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f3ed.svg")
}
.twa-woman-factory-worker-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f3ed.svg")
}
.twa-woman-factory-worker-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f3ed.svg")
}
.twa-woman-factory-worker-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f3ed.svg")
}
.twa-woman-factory-worker-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f3ed.svg")
}
.twa-woman-factory-worker-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f3ed.svg")
}
.twa-woman-fairy {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-200d-2640-fe0f.svg")
}
.twa-woman-fairy-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-fairy-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-fairy-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-fairy-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-fairy-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9da-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-farmer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f33e.svg")
}
.twa-woman-farmer-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f33e.svg")
}
.twa-woman-farmer-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f33e.svg")
}
.twa-woman-farmer-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f33e.svg")
}
.twa-woman-farmer-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f33e.svg")
}
.twa-woman-farmer-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f33e.svg")
}
.twa-woman-feeding-baby {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f37c.svg")
}
.twa-woman-feeding-baby-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f37c.svg")
}
.twa-woman-feeding-baby-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f37c.svg")
}
.twa-woman-feeding-baby-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f37c.svg")
}
.twa-woman-feeding-baby-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f37c.svg")
}
.twa-woman-feeding-baby-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f37c.svg")
}
.twa-woman-firefighter {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f692.svg")
}
.twa-woman-firefighter-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f692.svg")
}
.twa-woman-firefighter-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f692.svg")
}
.twa-woman-firefighter-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f692.svg")
}
.twa-woman-firefighter-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f692.svg")
}
.twa-woman-firefighter-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f692.svg")
}
.twa-woman-frowning {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-200d-2640-fe0f.svg")
}
.twa-woman-frowning-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-frowning-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-frowning-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-frowning-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-frowning-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64d-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-genie {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9de-200d-2640-fe0f.svg")
}
.twa-woman-gesturing-no {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-200d-2640-fe0f.svg")
}
.twa-woman-gesturing-no-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-gesturing-no-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-gesturing-no-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-gesturing-no-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-gesturing-no-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f645-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-gesturing-ok {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-200d-2640-fe0f.svg")
}
.twa-woman-gesturing-ok-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-gesturing-ok-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-gesturing-ok-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-gesturing-ok-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-gesturing-ok-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f646-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-getting-haircut {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-200d-2640-fe0f.svg")
}
.twa-woman-getting-haircut-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-getting-haircut-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-getting-haircut-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-getting-haircut-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-getting-haircut-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f487-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-getting-massage {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-200d-2640-fe0f.svg")
}
.twa-woman-getting-massage-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-getting-massage-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-getting-massage-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-getting-massage-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-getting-massage-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f486-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-golfing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-fe0f-200d-2640-fe0f.svg")
}
.twa-woman-golfing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-golfing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-golfing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-golfing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-golfing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cc-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-guard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-200d-2640-fe0f.svg")
}
.twa-woman-guard-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-guard-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-guard-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-guard-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-guard-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f482-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-health-worker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-2695-fe0f.svg")
}
.twa-woman-health-worker-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2695-fe0f.svg")
}
.twa-woman-health-worker-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2695-fe0f.svg")
}
.twa-woman-health-worker-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2695-fe0f.svg")
}
.twa-woman-health-worker-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2695-fe0f.svg")
}
.twa-woman-health-worker-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2695-fe0f.svg")
}
.twa-woman-in-lotus-position {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-200d-2640-fe0f.svg")
}
.twa-woman-in-lotus-position-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-in-lotus-position-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-in-lotus-position-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-in-lotus-position-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-in-lotus-position-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d8-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-in-manual-wheelchair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f9bd.svg")
}
.twa-woman-in-manual-wheelchair-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f9bd.svg")
}
.twa-woman-in-manual-wheelchair-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f9bd.svg")
}
.twa-woman-in-manual-wheelchair-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f9bd.svg")
}
.twa-woman-in-manual-wheelchair-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f9bd.svg")
}
.twa-woman-in-manual-wheelchair-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f9bd.svg")
}
.twa-woman-in-motorized-wheelchair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f9bc.svg")
}
.twa-woman-in-motorized-wheelchair-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f9bc.svg")
}
.twa-woman-in-motorized-wheelchair-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f9bc.svg")
}
.twa-woman-in-motorized-wheelchair-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f9bc.svg")
}
.twa-woman-in-motorized-wheelchair-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f9bc.svg")
}
.twa-woman-in-motorized-wheelchair-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f9bc.svg")
}
.twa-woman-in-steamy-room {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-200d-2640-fe0f.svg")
}
.twa-woman-in-steamy-room-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-in-steamy-room-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-in-steamy-room-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-in-steamy-room-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-in-steamy-room-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d6-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-in-tuxedo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-200d-2640-fe0f.svg")
}
.twa-woman-in-tuxedo-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-in-tuxedo-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-in-tuxedo-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-in-tuxedo-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-in-tuxedo-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f935-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-judge {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-2696-fe0f.svg")
}
.twa-woman-judge-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2696-fe0f.svg")
}
.twa-woman-judge-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2696-fe0f.svg")
}
.twa-woman-judge-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2696-fe0f.svg")
}
.twa-woman-judge-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2696-fe0f.svg")
}
.twa-woman-judge-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2696-fe0f.svg")
}
.twa-woman-juggling {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-200d-2640-fe0f.svg")
}
.twa-woman-juggling-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-juggling-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-juggling-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-juggling-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-juggling-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f939-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-kneeling {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-200d-2640-fe0f.svg")
}
.twa-woman-kneeling-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-kneeling-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-kneeling-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-kneeling-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-kneeling-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9ce-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-lifting-weights {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-fe0f-200d-2640-fe0f.svg")
}
.twa-woman-lifting-weights-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-lifting-weights-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-lifting-weights-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-lifting-weights-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-lifting-weights-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3cb-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb.svg")
}
.twa-woman-light-skin-tone-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f9b2.svg")
}
.twa-woman-light-skin-tone-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-light-skin-tone-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-light-skin-tone-christmas {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f384.svg")
}
.twa-woman-light-skin-tone-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f9b1.svg")
}
.twa-woman-light-skin-tone-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f9b0.svg")
}
.twa-woman-light-skin-tone-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f9b3.svg")
}
.twa-woman-mage {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-200d-2640-fe0f.svg")
}
.twa-woman-mage-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-mage-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-mage-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-mage-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-mage-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d9-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-mechanic {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f527.svg")
}
.twa-woman-mechanic-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f527.svg")
}
.twa-woman-mechanic-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f527.svg")
}
.twa-woman-mechanic-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f527.svg")
}
.twa-woman-mechanic-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f527.svg")
}
.twa-woman-mechanic-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f527.svg")
}
.twa-woman-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe.svg")
}
.twa-woman-medium-dark-skin-tone-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f9b2.svg")
}
.twa-woman-medium-dark-skin-tone-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-medium-dark-skin-tone-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-medium-dark-skin-tone-christmas {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f384.svg")
}
.twa-woman-medium-dark-skin-tone-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f9b1.svg")
}
.twa-woman-medium-dark-skin-tone-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f9b0.svg")
}
.twa-woman-medium-dark-skin-tone-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f9b3.svg")
}
.twa-woman-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc.svg")
}
.twa-woman-medium-light-skin-tone-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f9b2.svg")
}
.twa-woman-medium-light-skin-tone-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-medium-light-skin-tone-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-medium-light-skin-tone-christmas {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f384.svg")
}
.twa-woman-medium-light-skin-tone-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f9b1.svg")
}
.twa-woman-medium-light-skin-tone-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f9b0.svg")
}
.twa-woman-medium-light-skin-tone-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f9b3.svg")
}
.twa-woman-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd.svg")
}
.twa-woman-medium-skin-tone-bald {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f9b2.svg")
}
.twa-woman-medium-skin-tone-beard {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d4-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-medium-skin-tone-blond-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f471-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-medium-skin-tone-christmas {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f384.svg")
}
.twa-woman-medium-skin-tone-curly-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f9b1.svg")
}
.twa-woman-medium-skin-tone-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f9b0.svg")
}
.twa-woman-medium-skin-tone-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f9b3.svg")
}
.twa-woman-mountain-biking {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-200d-2640-fe0f.svg")
}
.twa-woman-mountain-biking-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-mountain-biking-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-mountain-biking-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-mountain-biking-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-mountain-biking-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b5-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-office-worker {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f4bc.svg")
}
.twa-woman-office-worker-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f4bc.svg")
}
.twa-woman-office-worker-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f4bc.svg")
}
.twa-woman-office-worker-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f4bc.svg")
}
.twa-woman-office-worker-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f4bc.svg")
}
.twa-woman-office-worker-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f4bc.svg")
}
.twa-woman-pilot {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-2708-fe0f.svg")
}
.twa-woman-pilot-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-2708-fe0f.svg")
}
.twa-woman-pilot-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-2708-fe0f.svg")
}
.twa-woman-pilot-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-2708-fe0f.svg")
}
.twa-woman-pilot-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-2708-fe0f.svg")
}
.twa-woman-pilot-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-2708-fe0f.svg")
}
.twa-woman-playing-handball {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-200d-2640-fe0f.svg")
}
.twa-woman-playing-handball-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-playing-handball-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-playing-handball-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-playing-handball-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-playing-handball-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93e-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-playing-water-polo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-200d-2640-fe0f.svg")
}
.twa-woman-playing-water-polo-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-playing-water-polo-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-playing-water-polo-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-playing-water-polo-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-playing-water-polo-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93d-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-police-officer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-200d-2640-fe0f.svg")
}
.twa-woman-police-officer-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-police-officer-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-police-officer-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-police-officer-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-police-officer-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46e-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-pouting {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-200d-2640-fe0f.svg")
}
.twa-woman-pouting-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-pouting-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-pouting-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-pouting-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-pouting-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64e-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-raising-hand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-200d-2640-fe0f.svg")
}
.twa-woman-raising-hand-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-raising-hand-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-raising-hand-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-raising-hand-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-raising-hand-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f64b-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-red-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f9b0.svg")
}
.twa-woman-rowing-boat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-200d-2640-fe0f.svg")
}
.twa-woman-rowing-boat-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-rowing-boat-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-rowing-boat-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-rowing-boat-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-rowing-boat-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6a3-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-running {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-200d-2640-fe0f.svg")
}
.twa-woman-running-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-running-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-running-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-running-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-running-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c3-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-s-boot {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f462.svg")
}
.twa-woman-s-clothes {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f45a.svg")
}
.twa-woman-s-hat {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f452.svg")
}
.twa-woman-s-sandal {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f461.svg")
}
.twa-woman-scientist {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f52c.svg")
}
.twa-woman-scientist-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f52c.svg")
}
.twa-woman-scientist-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f52c.svg")
}
.twa-woman-scientist-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f52c.svg")
}
.twa-woman-scientist-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f52c.svg")
}
.twa-woman-scientist-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f52c.svg")
}
.twa-woman-shrugging {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-200d-2640-fe0f.svg")
}
.twa-woman-shrugging-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-shrugging-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-shrugging-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-shrugging-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-shrugging-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f937-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-singer {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f3a4.svg")
}
.twa-woman-singer-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f3a4.svg")
}
.twa-woman-singer-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f3a4.svg")
}
.twa-woman-singer-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f3a4.svg")
}
.twa-woman-singer-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f3a4.svg")
}
.twa-woman-singer-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f3a4.svg")
}
.twa-woman-standing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-200d-2640-fe0f.svg")
}
.twa-woman-standing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-standing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-standing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-standing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-standing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9cd-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-student {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f393.svg")
}
.twa-woman-student-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f393.svg")
}
.twa-woman-student-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f393.svg")
}
.twa-woman-student-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f393.svg")
}
.twa-woman-student-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f393.svg")
}
.twa-woman-student-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f393.svg")
}
.twa-woman-superhero {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-200d-2640-fe0f.svg")
}
.twa-woman-superhero-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-superhero-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-superhero-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-superhero-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-superhero-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b8-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-supervillain {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-200d-2640-fe0f.svg")
}
.twa-woman-supervillain-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-supervillain-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-supervillain-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-supervillain-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-supervillain-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9b9-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-surfing {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-200d-2640-fe0f.svg")
}
.twa-woman-surfing-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-surfing-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-surfing-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-surfing-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-surfing-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3c4-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-swimming {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-200d-2640-fe0f.svg")
}
.twa-woman-swimming-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-swimming-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-swimming-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-swimming-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-swimming-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f3ca-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-teacher {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f3eb.svg")
}
.twa-woman-teacher-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f3eb.svg")
}
.twa-woman-teacher-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f3eb.svg")
}
.twa-woman-teacher-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f3eb.svg")
}
.twa-woman-teacher-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f3eb.svg")
}
.twa-woman-teacher-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f3eb.svg")
}
.twa-woman-technologist {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f4bb.svg")
}
.twa-woman-technologist-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f4bb.svg")
}
.twa-woman-technologist-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f4bb.svg")
}
.twa-woman-technologist-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f4bb.svg")
}
.twa-woman-technologist-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f4bb.svg")
}
.twa-woman-technologist-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f4bb.svg")
}
.twa-woman-tipping-hand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-200d-2640-fe0f.svg")
}
.twa-woman-tipping-hand-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-tipping-hand-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-tipping-hand-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-tipping-hand-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-tipping-hand-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f481-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-vampire {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-200d-2640-fe0f.svg")
}
.twa-woman-vampire-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-vampire-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-vampire-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-vampire-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-vampire-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9db-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-walking {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-200d-2640-fe0f.svg")
}
.twa-woman-walking-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-walking-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-walking-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-walking-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-walking-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6b6-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-wearing-turban {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-200d-2640-fe0f.svg")
}
.twa-woman-wearing-turban-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-wearing-turban-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-wearing-turban-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-wearing-turban-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-wearing-turban-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f473-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-white-hair {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f9b3.svg")
}
.twa-woman-with-headscarf {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d5.svg")
}
.twa-woman-with-headscarf-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d5-1f3ff.svg")
}
.twa-woman-with-headscarf-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d5-1f3fb.svg")
}
.twa-woman-with-headscarf-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d5-1f3fe.svg")
}
.twa-woman-with-headscarf-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d5-1f3fc.svg")
}
.twa-woman-with-headscarf-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9d5-1f3fd.svg")
}
.twa-woman-with-veil {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-200d-2640-fe0f.svg")
}
.twa-woman-with-veil-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-1f3ff-200d-2640-fe0f.svg")
}
.twa-woman-with-veil-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-1f3fb-200d-2640-fe0f.svg")
}
.twa-woman-with-veil-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-1f3fe-200d-2640-fe0f.svg")
}
.twa-woman-with-veil-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-1f3fc-200d-2640-fe0f.svg")
}
.twa-woman-with-veil-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f470-1f3fd-200d-2640-fe0f.svg")
}
.twa-woman-with-white-cane {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-200d-1f9af.svg")
}
.twa-woman-with-white-cane-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f9af.svg")
}
.twa-woman-with-white-cane-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f9af.svg")
}
.twa-woman-with-white-cane-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f9af.svg")
}
.twa-woman-with-white-cane-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f9af.svg")
}
.twa-woman-with-white-cane-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f9af.svg")
}
.twa-woman-zombie {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9df-200d-2640-fe0f.svg")
}
.twa-women-holding-hands {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46d.svg")
}
.twa-women-holding-hands-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46d-1f3ff.svg")
}
.twa-women-holding-hands-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f91d-200d-1f469-1f3fb.svg")
}
.twa-women-holding-hands-dark-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f91d-200d-1f469-1f3fe.svg")
}
.twa-women-holding-hands-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f91d-200d-1f469-1f3fc.svg")
}
.twa-women-holding-hands-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3ff-200d-1f91d-200d-1f469-1f3fd.svg")
}
.twa-women-holding-hands-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46d-1f3fb.svg")
}
.twa-women-holding-hands-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f91d-200d-1f469-1f3ff.svg")
}
.twa-women-holding-hands-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f91d-200d-1f469-1f3fe.svg")
}
.twa-women-holding-hands-light-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f91d-200d-1f469-1f3fc.svg")
}
.twa-women-holding-hands-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fb-200d-1f91d-200d-1f469-1f3fd.svg")
}
.twa-women-holding-hands-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46d-1f3fe.svg")
}
.twa-women-holding-hands-medium-dark-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f91d-200d-1f469-1f3ff.svg")
}
.twa-women-holding-hands-medium-dark-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f91d-200d-1f469-1f3fb.svg")
}
.twa-women-holding-hands-medium-dark-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f91d-200d-1f469-1f3fc.svg")
}
.twa-women-holding-hands-medium-dark-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fe-200d-1f91d-200d-1f469-1f3fd.svg")
}
.twa-women-holding-hands-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46d-1f3fc.svg")
}
.twa-women-holding-hands-medium-light-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f91d-200d-1f469-1f3ff.svg")
}
.twa-women-holding-hands-medium-light-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f91d-200d-1f469-1f3fb.svg")
}
.twa-women-holding-hands-medium-light-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f91d-200d-1f469-1f3fe.svg")
}
.twa-women-holding-hands-medium-light-skin-tone-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fc-200d-1f91d-200d-1f469-1f3fd.svg")
}
.twa-women-holding-hands-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46d-1f3fd.svg")
}
.twa-women-holding-hands-medium-skin-tone-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f91d-200d-1f469-1f3ff.svg")
}
.twa-women-holding-hands-medium-skin-tone-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f91d-200d-1f469-1f3fb.svg")
}
.twa-women-holding-hands-medium-skin-tone-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f91d-200d-1f469-1f3fe.svg")
}
.twa-women-holding-hands-medium-skin-tone-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f469-1f3fd-200d-1f91d-200d-1f469-1f3fc.svg")
}
.twa-women-s-room {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f6ba.svg")
}
.twa-women-with-bunny-ears {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f46f-200d-2640-fe0f.svg")
}
.twa-women-wrestling {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f93c-200d-2640-fe0f.svg")
}
.twa-wood {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fab5.svg")
}
.twa-woozy-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f974.svg")
}
.twa-world-map {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f5fa.svg")
}
.twa-worm {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fab1.svg")
}
.twa-worried-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f61f.svg")
}
.twa-wrapped-gift {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f381.svg")
}
.twa-wrench {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f527.svg")
}
.twa-writing-hand {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270d.svg")
}
.twa-writing-hand-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270d-1f3ff.svg")
}
.twa-writing-hand-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270d-1f3fb.svg")
}
.twa-writing-hand-medium-dark-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270d-1f3fe.svg")
}
.twa-writing-hand-medium-light-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270d-1f3fc.svg")
}
.twa-writing-hand-medium-skin-tone {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/270d-1f3fd.svg")
}
.twa-yarn {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9f6.svg")
}
.twa-yawning-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f971.svg")
}
.twa-yellow-circle {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f7e1.svg")
}
.twa-yellow-heart {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f49b.svg")
}
.twa-yellow-square {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f7e8.svg")
}
.twa-yen-banknote {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4b4.svg")
}
.twa-yin-yang {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/262f.svg")
}
.twa-yo-yo {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1fa80.svg")
}
.twa-zany-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f92a.svg")
}
.twa-zebra {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f993.svg")
}
.twa-zipper-mouth-face {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f910.svg")
}
.twa-zombie {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f9df.svg")
}
.twa-zzz {
    background-image: url("https://twemoji.maxcdn.com/v/latest/svg/1f4a4.svg")
}
